<template>
  <div id="editPage" class="signing-e-wrapper">
    <div class="e-header">
      <h2>템플릿 설정</h2>
    </div>
    <div class="e-container">
      <div class="e-cont">
        <signStepOne
          v-show="currentStep === 1"
          ref="stepOne"
          v-model:processType="processType"
          :isEditting="isEditting"
          operationType="template"
          @removeSigner="onRemoveSigner"
        />
        <EditPdf
          v-if="edittingData.length > 0 "
          v-show="currentStep === 2"
          ref="editPdf"
          v-model:pdfState="pdfState"
          :isShowField="currentStep===2"
          :processType="processType"
          operationType="template"
          @changeDocFile="onChangeDocFileModalShow"
        />
      </div>
    </div>
    <div class="e-footer">
      <div class="btns">
        <a-button class="btn btn-gray-line" @click="onClickBack">{{ currentStep === 2 ? '이전 단계로' : '나가기' }}</a-button>
        <a-button :class="dangerNext ? 'btn-disabled' : ''" :disabled="disableNext" class="btn " type="primary" @click="onClickNext">{{ currentStep === 2 ? '설정 완료' : '다음 단계로' }}</a-button>
      </div>
    </div>
  </div>
  <SimpleModal ref="exitModal" cancelText="취소" okText="나가기" @cancel="onCancel" @confirm="onCancelSave">
    <template #title>
      <h3>나가기</h3>
      <b class="text-gray">입력한 내용이 저장되지 않습니다.<br>정말 나가시겠습니까?</b>
    </template>
  </SimpleModal>
  <SimpleModal ref="saveModal" cancelText="취소" okText="저장 후 나가기" @cancel="onCancel" @confirm="saveTemplate">
    <template #title>
      <h3>저장하고 나가기</h3>
      <b class="text-gray">입력한 내용을 저장하고 나가시겠습니까?</b>
    </template>
    <template #content>
      <div class="form-group text-left">
        <label class="form-tit" for="pw">템플릿 이름</label>
        <a-input v-model:value="templateName" placeholder=" 2자 이상, 100자 이하로 입력해 주세요."/>
        <div class="error-info text-red"> {{ errorInfo }}</div>
      </div>
    </template>
  </SimpleModal>
  <SimpleModal ref="unwrittenModal" :width="520">
    <template #title>
      <div class="cus-icon">
        <slot name="cusIcon">
          <ExclamationCircleFilled class="icon"/>
        </slot>
      </div>
      <h3>서명조건 미입력</h3>
    </template>
    <template #textContent>
      모든 서명 참여자의 필수 서명란이 1개 이상 추가되어 있어야 합니다.<br/>
    </template>
    <template #footer>
      <a-button class="btn" type="primary" @click="unwrittenModal.hide()">확인</a-button>
    </template>
  </SimpleModal>
  <SimpleModal ref="changeDocFileModal" cancelText="취소" okText="변경하기">
    <template #title>
      <h3>문서 변경하기</h3>
    </template>
    <template #content>
      변경할 문서 형식에 따라 추가된 필드의 크기 및 위치가 달라질 수 있으니 문서 변경 후 확인 후 이용해 주세요.
    </template>
    <template #footer>
      <a-button class="btn btn-gray-line" @click="onChangeDocFileModalHide">취소</a-button>
      <a-upload
        :beforeUpload="beforeUpload"
        :showUploadList="false"
        accept=".hwp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .jpg, .jpge, .png, .ppts, .docs"
      >
        <a-button class="btn btn-blue">변경하기</a-button>
      </a-upload>
    </template>
  </SimpleModal>
</template>

<script>
import { computed, inject, nextTick, onBeforeMount, onUnmounted, reactive, ref, toRefs } from 'vue'
import signStepOne from './signStepOne'
import { useStore } from 'vuex'
import EditPdf from 'views/signCreating/editPdf'
import SimpleModal from 'components/common/SimpleModal'
import { useRoute, useRouter } from 'vue-router'
import { createTemplate, getTemplateDetail, updateTemplate } from 'api/template'
import { formatPdfData, regenerateSignData, revertSignData } from 'components/signing/createForm/createForm'
import { usePdf } from 'use/sign/usePdf'
import { Modal } from 'ant-design-vue'
import { useRouterSwitch } from 'use/sign/useRouterSwitch'
import { ExclamationCircleFilled } from '@ant-design/icons-vue'
import { DocumentProcessTypes } from 'utils/commonValue'
import { getFile } from 'api/file'
import { file2Base64 } from 'utils/helper'
import { uploadFile } from 'api/sign'

export default {
  components: {
    signStepOne,
    EditPdf,
    SimpleModal,
    ExclamationCircleFilled
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const exitModal = ref(null)
    const saveModal = ref(null)
    const editPdf = ref(null)
    const globalSpin = inject('globalSpin')
    const stepOne = ref(null)
    const unwrittenModal = ref(null)
    const changeDocFileModal = ref(null)
    const state = reactive({
      currentStep: 1,
      templateName: '',
      edittingData: [],
      errorInfo: '',
      type: '',
      redirectUrl: route.query.redirectUrl,
      processType: DocumentProcessTypes.PROCEDURE,
      changeDocFileVis: false,
      uploadFile: null
    })

    const isEditting = computed(() => {
      return Boolean(route.params.templateId)
    })

    const formatSigners = (participants) => {
      const signers = participants.map(item => {
        let attachments = []
        if (item.attachments.length > 0) {
          attachments = item.attachments.map(item => {
            return {
              attachmentType: item.request.attachmentType,
              description: item.request.description,
              required: item.request.required
            }
          })
        }
        return {
          roleName: item.roleName,
          name: item.name,
          signingMethodType: item.signingMethodType,
          signingOrder: item.signingOrder,
          signingContactInfo: item.signingContactInfo,
          message: item.message || '',
          attachments,
          dragKey: item.participantId
        }
      })
      store.dispatch('updateSigners', signers)
    }

    const disableNext = computed(() => {
      if (state.currentStep === 1) {
        const hasUnfinishedInfo = store.state.documents.signers.findIndex(item => {
          return item.roleName.trim() === ''
        })
        return hasUnfinishedInfo !== -1
      }
      return false
    })

    const onClickBack = () => {
      if (state.currentStep === 1) {
        const hasUnfinishedInfo = store.state.documents.signers.findIndex(item => item.roleName.trim() === '')
        if (hasUnfinishedInfo) {
          exitModal.value.show()
        } else {
          router.back()
        }
      } else if (state.currentStep === 2) {
        editPdf.value.stopKeyboardEvent()
        state.currentStep--
      }
    }

    const onClickNext = () => {
      if (state.currentStep === 1) {
        const orderMap = stepOne.value.refreshOrder()
        refreshFields(orderMap)
        editPdf.value.startKeyboardEvent()
        editPdf.value.checkParticipantsSignFields()
        state.currentStep++
      } else if (state.currentStep === 2) {
        if (!store.state.documents.isAllSignFilled) {
          unwrittenModal.value.show()
          return false
        }
        saveModal.value.show()
      }
    }

    const dangerNext = computed(() => {
      return state.currentStep === 2 && !store.state.documents.isAllSignFilled
    })

    const onCancel = () => {
      if (state.currentStep === 1) exitModal.value.hide()
      if (state.currentStep === 2) saveModal.value.hide()
    }

    const onCancelSave = () => {
      router.back()
    }

    const saveTemplate = () => {
      if (state.templateName.length >= 2 && state.templateName.length <= 100) {
        state.errorInfo = ''
        onFinish()
      } else {
        state.errorInfo = '2자 이상, 100자 이하로 입력해 주세요.'
      }
    }

    const { toTemplatePage } = useRouterSwitch()

    const onFinish = () => {
      globalSpin.show()
      saveModal.value.hide()
      const documentInfo = { ...store.state.documents.documentInfo }
      const participants = [...store.state.documents.signers]
      const pdfData = { ...store.state.documents.pdfData }
      const groups = { ...store.state.documents.fieldGroup }
      // const pageSize = editPdf.value.getPageSize()
      const {
        requesterInputs,
        fields
      } = formatPdfData(pdfData, pdfData, groups)
      participants.forEach((item, index) => {
        delete item.dragKey
        item.signingContactInfo = item.signingContactInfo || ''
        item.fields = fields.filter(field => field.signingOrder === index + 1)
      })

      const requestData = {
        ...documentInfo,
        processType: state.processType,
        participants,
        requesterInputs,
        fileId: store.state.documents.currentPdf.fileId,
        name: state.templateName
      }
      if (isEditting.value) {
        requestData.documentId = route.params.templateId
      }
      let operationFn = isEditting.value ? updateTemplate : createTemplate
      if (state.type === 'SMTP') {
        operationFn = createTemplate
        requestData.fileId = store.state.documents.currentPdf.fileId
        operationFn(requestData).then(res => {
          if (route.query.redirectUrl) {
            let link = `${route.query.redirectUrl}?documentId=${res.result.documentId}`
            if (isEditting.value) {
              link += '&action=template_modifying'
            } else {
              link += '&action=template_creating'
            }
            window.location.href = decodeURIComponent(link)
          } else {
            toTemplatePage('replace')
          }
        }).finally(() => {
          globalSpin.hide()
        })
      } else {
        operationFn(requestData).then(res => {
          if (route.query.redirectUrl) {
            let link = `${route.query.redirectUrl}?documentId=${res.result.documentId}`
            if (isEditting.value) {
              link += '&action=template_modifying'
            } else {
              link += '&action=template_creating'
            }
            window.location.href = decodeURIComponent(link)
          } else {
            toTemplatePage('replace')
          }
        }).finally(() => {
          globalSpin.hide()
        })
      }
    }

    const {
      pdfState,
      loadPdf,
      destroyPdf,
      renderPdf,
      renderPdfPreviewer
    } = usePdf()

    const initTemplatePage = async () => {
      try {
        await store.dispatch('updateSigners', [])
        globalSpin.show()
        if (isEditting.value) { // editting template
          const res = await getTemplateDetail(route.params.templateId)
          state.processType = res.result.processType
          const currentPdf = {
            name: res.result.name,
            fileId: res.result.fileId
          }
          state.type = res.result.type
          if (res.result.type !== 'SMTP') {
            state.templateName = res.result.name
          }
          await store.dispatch('setCurrentPdf', currentPdf)
          formatSigners(res.result.participants)
          const fileRes = await getFile({ documentId: route.params.templateId })
          if (fileRes.result) {
            const loadPdfResult = await loadPdf(atob(fileRes.result.file))
            if (loadPdfResult.length > 0) {
              const {
                formattedPdfData,
                fieldGroup
              } = revertSignData(loadPdfResult, res.result.requesterInputs, res.result.participants)
              await store.dispatch('setPdfData', formattedPdfData)
              await store.dispatch('setFieldGroup', fieldGroup)
              await store.dispatch('pushHistoryStorage')
              state.edittingData = formattedPdfData
              console.log(state.edittingData)
            }
            renderPdf()
            renderPdfPreviewer()
          } else {
            Modal.error({
              title: '문서 불러오기에 실패했습니다',
              onOk () {
                router.back()
              }
            })
          }
          globalSpin.hide()
        } else {
          // create template
          const fileRes = await getFile({ fileId: store.state.documents.currentPdf.fileId })
          if (fileRes.result) {
            const loadPdfResult = await loadPdf(atob(fileRes.result.file))
            state.edittingData = loadPdfResult.map(item => {
              item.fields = []
              item.requesterFields = []
              return item
            })
            await store.dispatch('setPdfData', state.edittingData)
            await nextTick(() => {
              renderPdf()
              renderPdfPreviewer()
            })
          } else {
            Modal.error({
              title: '문서 불러오기에 실패했습니다',
              onOk () {
                router.back()
              }
            })
          }
          globalSpin.hide()
        }
      } catch (error) {
        globalSpin.hide()
        Modal.error({
          title: error.message || 'Data load failed.',
          onOk () {
            router.back()
          }
        })
      }
    }

    const refreshFields = (orderMap) => {
      store.state.documents.pdfData.forEach(pdf => {
        if (pdf.fields.length > 0) {
          pdf.fields.forEach(field => {
            field.signingOrder = orderMap[field.dragKey]
          })
        }
      })
    }

    const onRemoveSigner = (dragKey) => {
      store.state.documents.pdfData.forEach(pdf => {
        if (pdf.fields.length > 0) {
          pdf.fields = pdf.fields.filter(field => field.dragKey !== dragKey)
        }
      })
    }

    onBeforeMount(() => {
      store.dispatch('resetEditData')
      initTemplatePage()
    })

    onUnmounted(() => {
      store.state.documents.signers = []
      store.commit('SET_CURRENT_PDF', null)
    })

    const onChangeDocFileModalShow = () => {
      changeDocFileModal.value.show()
    }

    const onChangeDocFileModalHide = () => {
      changeDocFileModal.value.hide()
    }

    const beforeUpload = async file => {
      const limitSize = 5 * 1024 * 1024
      if (file.size > limitSize) {
        Modal.error({
          content: '파일 업로드 최대 용량은 5MB 입니다.'
        })
        return false
      } else {
        destroyPdf()
        state.uploadFile = file
        changeDocFileModal.value.hide()
        const formatFile = await file2Base64(state.uploadFile)
        const nameArray = state.uploadFile.name.split('.')
        uploadFile({
          base64: formatFile.split(',')[1],
          ext: '.' + nameArray[nameArray.length - 1]
        }).then(async res => {
          globalSpin.show()
          if (res) {
            const currentPdf = {
              name: state.uploadFile.name,
              fileId: res.result.fileId
            }
            await store.dispatch('setCurrentPdf', currentPdf)
            const fileRes = await getFile({ fileId: res.result.fileId })
            if (fileRes.result) {
              await store.dispatch('setPdfData', [])
              const loadPdfResult = await loadPdf(atob(fileRes.result.file))
              const prevData = state.edittingData
              const newData = loadPdfResult.map(item => {
                item.fields = []
                item.requesterFields = []
                return item
              })
              const { newPdfData } = regenerateSignData(prevData, newData)
              state.edittingData = newPdfData
              await store.dispatch('setPdfData', state.edittingData)
              await nextTick(() => {
                const group = store.state.documents.fieldGroup
                store.dispatch('resetEditData')
                store.dispatch('setFieldGroup', group)
                store.dispatch('pushHistoryStorage')
                renderPdf()
                renderPdfPreviewer()
                nextTick(() => {
                  editPdf.value.setting()
                })
              })
            } else {
              Modal.error({
                title: '문서 불러오기에 실패했습니다',
                onOk () {
                  router.back()
                }
              })
            }
          }
        }).catch(error => {
          console.error(error)
          Modal.error({
            content: error.msg
          })
        }).finally(() => {
          globalSpin.hide()
        })
      }
    }

    return {
      unwrittenModal,
      changeDocFileModal,
      disableNext,
      onClickBack,
      onClickNext,
      dangerNext,
      exitModal,
      saveModal,
      onCancelSave,
      saveTemplate,
      editPdf,
      isEditting,
      stepOne,
      onRemoveSigner,
      onCancel,
      ...toRefs(state),
      pdfState,
      onChangeDocFileModalShow,
      onChangeDocFileModalHide,
      beforeUpload
    }
  }
}
</script>

<style lang="less" scoped>
.editor-wrap {
  min-height: 100vh;

  .e-fixed {
    z-index: 1000;
    text-align: center;
  }
}

.e-footer {
  .btns {
    text-align: center;

    .btn {
      width: 165px;
      height: 40px;

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

.cus-icon {
  margin-top: 16px;
  text-align: center;

  .icon {
    font-size: 60px;
    color: #1890ff;
  }
}
</style>
