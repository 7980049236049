<template>
  <a-spin :spinning="isLoading">
    <div class="stamp-container">
      <div class="topInfo upload-wrap">
        <div v-if="!file">
          <small class="text-gray"><p>{{ tipWords.tip }}</p>
            <p class="break-sm">(50MB 이하의 이미지 파일)</p></small>
          <div class="file-box">
            <a-button type="primary" class="input-button">
              <input type="file" class="file-input" accept="image/*" @change="onSelectFile">{{ tipWords.btn }}
            </a-button>
          </div>
        </div>
        <div v-else>
          <small class="text-gray" style="margin-bottom: 25px;">영역을 드래그하여 크기를 정하고, <span class="break-sm">대비를 조절한 후 입력하기 버튼을 누르세요.</span></small>
          <div class="file-box">
            <div class="input-group form-control form-lg">
              <input type="text" class="form-upload ellipsis" placeholder="선택된 파일 없음" :value="file.name" readonly="">
              <b role="button" @click="removeImage" class="input-group-addon text-gray">취소</b>
            </div>
          </div>
        </div>
      </div>
      <canvas id="imageHandler" style="display: none"></canvas>
      <div class="preview-wrapper">
        <CropTool
          v-if="imageDataUrl"
          :boxStyle="{
            width: '100%',
            height: '100%',
            margin: 'auto',
          }"
          :img="imageDataUrl"
          :options="{
            viewMode: 0,
            dragMode: 'none',
            aspectRatio: aspectRatio
          }"
        />
      </div>
      <div class="operation">
        <div class="slider-wrapper">
          <span>약한 대비</span>
          <a-slider class="slider" v-model:value="range" :min="1" :max="100" @afterChange="onChange" :disabled="isDisabled"/>
          <span>강한 대비</span>
        </div>
        <a-button @click="rotateImage" :disabled="isDisabled" type="text">
          <span role="img" class="anticon anticon-reload mr3">
            <svg class="" data-icon="reload" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896" focusable="false">
              <path
                d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z">
              </path>
            </svg>
          </span>
          이미지 회전
        </a-button>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { computed, h, reactive, toRefs } from 'vue'
import { file2Base64 } from 'utils/helper'
// import { FileFilled } from '@ant-design/icons-vue'
import ImageHandler from 'utils/imageHandler'
import CropTool, { cropper } from 'components/signing/signaure/CropTool'
import { Modal } from 'ant-design-vue'
import Compressor from 'compressorjs'

const MAX_FILE_SIZE = 50 * 1024 * 1024
const BASE_FILE_SIZE = 1 * 1024 * 1024

export default {
  components: { CropTool },
  props: ['type'],
  setup (props, { emit }) {
    const state = reactive({
      file: null,
      imageDataUrl: '',
      range: 1,
      imageHandler: null,
      isDisabled: true,
      isLoading: false
    })
    const onSelectFile = (event) => {
      state.isLoading = true
      const file = event.target.files[0]
      if (file.size > MAX_FILE_SIZE) {
        state.file = null
        Modal.error({
          title: '파일의 용량이 너무 큽니다.',
          content: [
            h('p', `업로드하신 파일의 용량 : ${Math.ceil(file.size / 1024 / 1024)}MB`),
            h('p', '업로드 용량 제한 : 50MB')
          ]
        })
        state.isLoading = false
        return false
      } else if (file.size < MAX_FILE_SIZE && file.size > BASE_FILE_SIZE) {
        // const ratio = 1 / Math.ceil(file.size)
        const ratio = 0.1
        // eslint-disable-next-line no-unused-vars
        const compressor = new Compressor(file, {
          quality: ratio,
          maxWidth: 1300,
          maxHeight: 500,
          success (result) {
            state.file = result
            file2Base64(result).then(res => {
              drawImage(res)
            })
            state.isDisabled = false
            state.range = 30
          },
          error (error) {
            state.isLoading = false
            console.log(error)
          }
        })
      } else {
        state.file = file
        file2Base64(file).then(res => {
          drawImage(res)
        })
        state.isDisabled = false
        state.range = 30
      }
    }
    const drawImage = (imageData) => {
      const canvasDom = document.getElementById('imageHandler')
      const img = new Image()
      img.src = imageData
      img.onload = () => {
        const color = props.type === 'stamp' ? 'red' : 'black'
        const imageHandler = new ImageHandler(canvasDom, img, 580, 255, color)
        state.imageHandler = imageHandler
        state.imageDataUrl = imageHandler.getImageDataUrl()
        state.isLoading = false
      }
    }
    const onChange = (value) => {
      state.imageHandler.transImage(value)
      state.imageDataUrl = state.imageHandler.getImageDataUrl()
    }
    const rotateImage = () => {
      cropper.rotate(45)
    }
    const removeImage = () => {
      state.file = null
      state.imageDataUrl = ''
      state.isDisabled = true
      state.range = 1
    }
    const aspectRatio = computed(() => {
      return props.type === 'stamp' ? 1 / 1 : 26 / 10
    })
    const getCropedImage = () => {
      if (state.file === null) {
        return null
      } else {
        return cropper.getDataURL()
      }
    }
    const tipWords = computed(() => {
      const stamp = {
        tip: '실제 도장 이미지를 업로드 해주세요.',
        btn: '도장 이미지 업로드'
      }
      const sign = {
        tip: '실제 사인 이미지를 업로드 해주세요.',
        btn: '사인 이미지 업로드'
      }
      return props.type === 'stamp' ? stamp : sign
    })
    return {
      onSelectFile,
      onChange,
      rotateImage,
      removeImage,
      aspectRatio,
      getCropedImage,
      tipWords,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.stamp-container {
  padding: 0 20px;

  .topInfo {
    text-align: center;
    line-height: 25px;

    .tipword {
      font-size: 16px;
    }

    .file-box {
      margin: 10px auto;
    }

    .fileInfo {
      margin: 0 auto;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      height: 34px;
      background: rgb(248, 248, 248);
      border: 1px solid rgb(224, 224, 224);
      border-radius: 5px;
      color: rgb(41, 41, 41);
      font-weight: 400;

      .filename {
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .preview-wrapper {
    margin: 15px auto;
    // width: 580px;
    height: 200px;
    background: url('~assets/images/transparent_bg.png');
  }

  .operation {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 580px;

    .slider-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75%;

      .slider {
        flex: 1;
      }

      span {
        padding: 0 5px;
      }
    }
  }

  .input-button {
    position: relative;
    padding: 0px 15px;
    overflow: hidden;
    cursor: pointer;

    .file-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }

  @media screen and (max-width: 640px) {
    .operation {
      width: 100%
    }
  }
}

</style>
