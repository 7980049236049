<template>
  <div class="gs-wrap">

    <div class="sign-row" v-if="type === 'stamp'">
      <a-select v-model:value="language" @change="onStampInput">
        <a-select-option value="KR">한글</a-select-option>
        <a-select-option value="CN">한문</a-select-option>
      </a-select>
      <div class="input-box">
        <a-input v-model:value="nameWord" placeholder="이름을 입력하고 만들기 버튼을 눌러주세요." @input="onStampInput" :lazy="false"/>
      </div>
      <a-button type="primary" @click="generate" :loading="isLoading">만들기</a-button>
    </div>
    <a-row class="name-form" :gutter="8" v-else>
      <a-col span="20">
        <a-input v-model:value="nameWord" placeholder="이름을 입력하고 만들기 버튼을 눌러주세요." @input="onSignInput" :lazy="false"/>
      </a-col>
      <a-col span="4">
        <a-button type="primary" @click="generate" :loading="isLoading">만들기</a-button>
      </a-col>
    </a-row>
    <div class="error-info text-red"> {{ errorInfo }}</div>

    <div class="preview-container bg-light-gray">
      <div class="preview" :class="{ 'sign-preivew': type === 'signature' }">
        <div class="svg-item" :class="{selected: currentSelected === index, stamp: type === 'stamp'}" v-for="(stamp, index) in svgs" :key="index" v-html="stamp" @click="onSelectStamp(index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, reactive, toRefs } from 'vue'
import { signatureFonts, signatureStamp } from 'api/sign'

export default {
  props: ['type', 'ownerType'],
  setup (props, { emit }) {
    const state = reactive({
      nameWord: '',
      isLoading: false,
      svgs: [],
      currentSelected: -1,
      language: 'KR',
      errorInfo: ''
    })
    const generate = () => {
      if (props.type === 'stamp') {
        onStampInput()
      } else {
        onSignInput()
      }
      if (state.errorInfo !== '') return false
      const func = props.type === 'stamp' ? signatureStamp : signatureFonts
      state.isLoading = true
      func(state.nameWord, props.ownerType, state.language).then(res => {
        state.svgs = res.result
        nextTick(() => {
          const svgs = document.querySelectorAll('.svg-item svg')
          svgs.forEach(item => {
            item.style.width = '100%'
            item.style.height = '100%'
          })
        })
      }).finally(() => {
        state.isLoading = false
      })
    }
    const onSelectStamp = (index) => {
      state.currentSelected = index
      emit('selectSvg', state.svgs[index])
    }

    const onStampInput = () => {
      if (state.nameWord.length === 0) {
        state.errorInfo = '최소 1글자 이상 입력해 주세요.'
      } else {
        if (state.language === 'CN') {
          if (state.nameWord.length <= 9) {
            const reg = /^[\u2E80-\u2EFF\u3400-\u4DBF\u4E00-\u9FBF\uF900-\uFAFF]{1,9}$/igm
            if (!state.nameWord.match(reg)) {
              state.errorInfo = '9자 이내의 한자로 입력해주세요.'
            } else {
              state.errorInfo = ''
            }
          } else {
            state.errorInfo = '9자 이내의 한자로 입력해주세요.'
          }
        } else if (state.language === 'KR') {
          if (state.nameWord.length <= 9) {
            const reg = /^(?:[a-zA-Z\d가-힣ㄱ-ㅎㅏ-ㅣ]{1,9})$/igm
            if (!state.nameWord.match(reg)) {
              state.errorInfo = '9자 이내의 한글, 영어, 숫자로 입력해주세요.'
            } else {
              state.errorInfo = ''
            }
          } else {
            state.errorInfo = '9자 이내의 한글, 영어, 숫자로 입력해주세요.'
          }
        } else {
          state.errorInfo = ''
        }
      }
    }

    const onSignInput = () => {
      if (state.nameWord.length <= 0) {
        state.errorInfo = '최소 1글자 이상의 한글, 영어, 숫자를 입력해 주세요'
      }
      if (state.nameWord.length <= 10) {
        const reg = /^(?:[a-zA-Z\d가-힣ㄱ-ㅎㅏ-ㅣ]{1,10})$/igm
        if (!state.nameWord.match(reg)) {
          state.errorInfo = '10자 이내의 한글, 영어, 숫자로 입력해 주세요'
        } else {
          state.errorInfo = ''
        }
      } else {
        state.errorInfo = '10자 이내의 한글, 영어, 숫자로 입력해 주세요'
      }
    }

    return {
      generate,
      onSelectStamp,
      onStampInput,
      onSignInput,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.gs-wrap {

  .sign-row {
    margin: 0 auto;
    padding: 0 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .input-box {
      padding: 0 10px;
      flex: 1;
    }
  }

  .tip {
    text-align: center;
  }

  .name-form {
    padding: 0 15%;
  }

  .error-info {
    min-height: 22px;
    padding: 0 15%;
  }

  .preview-container {
    margin: 0 auto;
    padding: 14px;
    max-width: 580px;
    height: 40vh;
    overflow-y: scroll;
    border: 1px solid #eee;

  }

  .preview {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .svg-item {
      box-sizing: border-box;
      background: #FFF;
      cursor: pointer;
      font-size: 0;
      border: 1px solid #CCC;
      @media (hover: hover) {
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .item-img {
        width: 100%;
        border: 1px solid #CCC;
        box-sizing: border-box;
      }
    }

    .selected {
      border-color: #BBB;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .sign-preivew {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .gs-wrap {
    .sign-row {
      padding: 0 6%;
    }

    .error-info {
      padding: 0 6%;
    }

    .preview {
      grid-template-columns: repeat(2, 1fr);

    }

    .sign-preivew {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

</style>
