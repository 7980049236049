import { onMounted, onUnmounted, reactive, toRefs } from 'vue'

export const useMousemove = () => {
  const mousemoveState = reactive({
    mouseLocation: {
      x: 0,
      y: 0
    }
  })
  const startWatchMouse = () => {
    mousemoveState.watchMove = true
    window.onmousemove = (event) => {
      mousemoveState.mouseLocation.x = event.clientX
      mousemoveState.mouseLocation.y = event.clientY
    }
  }
  const stopWatchMouse = () => {
    window.onmousemove = null
    mousemoveState.mouseLocation.x = 0
    mousemoveState.mouseLocation.y = 0
  }
  onMounted(() => {
    window.onclick = event => {
      mousemoveState.mouseLocation.x = event.clientX
      mousemoveState.mouseLocation.y = event.clientY
    }
  })
  onUnmounted(() => {
    stopWatchMouse()
    window.onclick = null
  })

  return {
    ...toRefs(mousemoveState),
    startWatchMouse,
    stopWatchMouse
  }
}
