<template>
  <div class="move-form" :class="cls">
    <div class="e-control e-chk">
      <svg aria-label="check icon" height="100%" role="img" viewBox="0 0 14 14" width="100%">
        <polyline fill="none" opacity="1" points="2.293 6.73 5.842 10.052 11.707 3.948" stroke="#000" stroke-width="1.8"></polyline>
      </svg>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watchEffect } from 'vue'

export default {
  props: ['active', 'signField', 'required'],
  setup (props, { emit }) {
    const state = reactive({
      isRequired: props.required
    })
    const cls = computed(() => {
      const order = props.signField.signingOrder || 1
      const name = props.signField.formType === 'requesterFields' ? 'sign-basic' : `sign${order}`
      return {
        'e-sure': state.isRequired,
        [name]: true
      }
    })
    watchEffect(() => {
      state.isRequired = props.required
    })
    return {
      cls,
      ...toRefs(props),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.move-form {
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.e-chk {
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.form-item {
  width: 100%;
  height: 100%;
}
</style>
