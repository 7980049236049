import { genKey } from 'utils/helper'
import { validDate } from 'utils/validate'
import dayjs from 'dayjs'

export const createSignerFild = (fieldInfo, pdfW, pdfH, scale) => {
  if (fieldInfo.type === 'group') {
    return {
      fieldType: fieldInfo.type,
      groupName: fieldInfo.name,
      groupMin: fieldInfo.min,
      groupMax: fieldInfo.max,
      groupRangeOption: fieldInfo.groupRangeOption,
      required: false,
      signingOrder: fieldInfo.signingOrder,
      fieldName: '그룹'
    }
  } else {
    const result = {
      fieldType: fieldInfo.type, // 签名标签类型    signature｜checkbox｜text
      locationPage: fieldInfo.locationPage, // 控件在文档的页码位置
      locationX: fieldInfo.x / (pdfW * scale), // 控件的x坐标/文档当前页的宽度
      locationY: fieldInfo.y / (pdfH * scale), // 控件的y坐标/文档当前页的高度
      locationZ: 0, // 控件的z坐标
      required: fieldInfo.required, // 控件是否是必须的 当fieldType为signature时   为true
      sizeHeight: fieldInfo.h / (pdfH * scale), // 控件的宽度/文档当前页的宽度
      sizeWidth: fieldInfo.w / (pdfW * scale), // 控件的高度/文档当前页的高度
      // title: `${fieldInfo.type}-${fieldInfo.locationPage}-${fieldInfo.signingOrder}`, // 控件的title，
      fieldName: fieldInfo.fieldName,
      isMoving: false
    }

    if (fieldInfo.fieldName.replaceAll(' ', '') === '' || typeof result.fieldName === 'undefined') {
      if (fieldInfo.type === 'text') {
        result.fieldName = '텍스트'
      } else if (fieldInfo.type === 'date') {
        result.fieldName = '날짜'
      } else if (fieldInfo.type === 'number') {
        result.fieldName = '숫자'
      } else if (fieldInfo.type === 'signature') {
        result.fieldName = '서명'
      } else if (fieldInfo.type === 'checkbox') {
        result.fieldName = '체크박스'
      } else {
        result.fieldName = '미정'
      }
    }

    if (fieldInfo.type === 'text' || fieldInfo.type === 'date' || fieldInfo.type === 'number') {
      result.textStyleFontSize = fieldInfo.textStyleFontSize
      result.textStyleFontType = fieldInfo.textStyleFontType
      result.textAlign = fieldInfo.textAlign
      result.textValueText = fieldInfo.textValueText
      if (fieldInfo.type === 'date') result.dateFormat = fieldInfo.dateFormat
      if (fieldInfo.type === 'number') result.addComma = fieldInfo.addComma
    }
    if (fieldInfo.type === 'signature') {
      const allowSignatureTypes = []
      fieldInfo.value === 'USER' ? allowSignatureTypes.push('SIGN', 'STAMP') : allowSignatureTypes.push(fieldInfo.value)
      result.allowSignatureTypes = allowSignatureTypes
      if (fieldInfo.signData) {
        result.signatureId = fieldInfo.signData.signatureId
      }
    }
    if (fieldInfo.type === 'checkbox') {
      if (fieldInfo.formType === 'requesterFields') result.checkboxInputChecked = true
      if (fieldInfo.groupParentName) result.groupParentName = fieldInfo.groupParentName
    }
    if (fieldInfo.formType === 'fields') result.signingOrder = fieldInfo.signingOrder
    return result
  }
}

export const formatPdfData = (pdfData, pageSize, fieldGroup) => {
  const requesterInputs = []
  const fields = []
  for (const key in pdfData) {
    const requesterField = pdfData[key].requesterFields.map(item => {
      return createSignerFild(item, pageSize[key].pdfW, pageSize[key].pdfH, pageSize[key].scale)
    })
    requesterInputs.push(...requesterField)
    const signerField = pdfData[key].fields.map(item => {
      return createSignerFild(item, pageSize[key].pdfW, pageSize[key].pdfH, pageSize[key].scale)
    })
    fields.push(...signerField)
  }
  for (const index in fieldGroup) {
    const group = createSignerFild(fieldGroup[index], null, null, null)
    fields.push(group)
  }
  return {
    requesterInputs,
    fields
  }
}

export const createSignItem = (field, pdfW, pdfH, scale) => {
  const result = {
    fieldId: field.fieldId,
    fieldType: field.fieldType,
    locationPage: field.locationPage,
    locationX: field.locationX,
    locationY: field.locationY,
    locationZ: 0,
    sizeHeight: field.sizeHeight,
    sizeWidth: field.sizeWidth
  }
  if (field.fieldType === 'signature') {
    result.locationX = field.signData.x / (pdfW * scale)
    result.locationY = field.signData.y / (pdfH * scale)
    result.sizeHeight = field.signData.h / (pdfH * scale)
    result.sizeWidth = field.signData.w / (pdfW * scale)
    result.signatureId = field.signData.signatureId
  }
  if (field.fieldType === 'text' || field.fieldType === 'date' || field.fieldType === 'number') {
    result.textStyleFontSize = field.textStyleFontSize
    result.textStyleFontType = field.textStyleFontType
    result.textValueText = field.signData.textValue
    result.textAlign = field.textAlign
    if (field.fieldType === 'date') result.dateFormat = field.dateFormat
    if (field.fieldType === 'number') result.addComma = field.addComma
  }
  if (field.fieldType === 'checkbox') result.checkboxInputChecked = field.signData.isChecked
  return result
}

export const formatSubmitData = (pdfRenderData) => {
  const fields = []
  pdfRenderData.forEach(item => {
    if (item.fields) fields.push(...item.fields)
  })
  return fields.map(item => {
    const pageIndex = item.locationPage - 1
    return createSignItem(item, pdfRenderData[pageIndex].pdfW, pdfRenderData[pageIndex].pdfH, pdfRenderData[pageIndex].scale)
  })
}

export const revertRequestField = (fieldData, pdfW, pdfH, scale) => {
  const requestField = {
    formType: 'requesterFields',
    type: fieldData.fieldType,
    x: Math.round(fieldData.locationX * pdfW * scale),
    y: Math.round(fieldData.locationY * pdfH * scale),
    w: Math.round(fieldData.sizeWidth * pdfW * scale),
    h: Math.round(fieldData.sizeHeight * pdfH * scale),
    key: `${fieldData.fieldType}-${genKey()}`,
    z: fieldData.locationZ,
    active: false,
    locationPage: fieldData.locationPage,
    fieldId: fieldData.fieldId,
    required: fieldData.required,
    fieldName: fieldData.fieldName
  }
  if (requestField.type === 'text') {
    requestField.textStyleFontSize = fieldData.textStyleFontSize
    requestField.textStyleFontType = fieldData.textStyleFontType
    requestField.textValueText = fieldData.textValueText
    requestField.textAlign = fieldData.textAlign
  }
  if (requestField.type === 'signature') {
    if (fieldData.signatureId) {
      requestField.signData = {}
      requestField.signData.signatureId = fieldData.signatureId
    }
    requestField.value = fieldData.allowSignatureTypes.length === 2 ? 'USER' : fieldData.allowSignatureTypes[0]
    requestField.signingOrder = 0
  }
  return requestField
}

export const revertField = (fieldData, pdfW, pdfH, scale, signingOrder, participantId) => {
  if (fieldData.fieldType === 'group') {
    return {
      type: fieldData.fieldType,
      name: fieldData.groupName,
      min: fieldData.groupMin,
      max: fieldData.groupMax,
      groupRangeOption: fieldData.groupRangeOption,
      count: fieldData.count,
      signingOrder
    }
  }
  if (fieldData.fieldType === 'signature' && fieldData.sizeWidth === 0 && fieldData.sizeHeight === 0) {
    console.log('❕Old signature field detected❕')
    fieldData.sizeWidth = 0.1028
    fieldData.sizeHeight = 0.0523
    fieldData.locationX = fieldData.locationX - fieldData.sizeWidth / 2
    fieldData.locationY = fieldData.locationY - fieldData.sizeHeight / 2
  }
  const field = {
    formType: 'fields',
    type: fieldData.fieldType,
    x: Math.round(fieldData.locationX * pdfW * scale),
    y: Math.round(fieldData.locationY * pdfH * scale),
    w: Math.round(fieldData.sizeWidth * pdfW * scale),
    h: Math.round(fieldData.sizeHeight * pdfH * scale),
    z: fieldData.locationZ,
    // key: `${fieldData.fieldType}-${signingOrder}-${fieldData.locationPage}`,
    key: `${fieldData.fieldType}-${genKey()}`,
    active: false,
    locationPage: fieldData.locationPage,
    fieldId: fieldData.fieldId,
    dragKey: participantId,
    signingOrder,
    required: fieldData.required,
    fieldName: fieldData.fieldName,
    groupParentName: fieldData.groupParentName
  }

  if (field.type === 'text' || field.type === 'date' || field.type === 'number') {
    field.textStyleFontSize = fieldData.textStyleFontSize
    field.textStyleFontType = fieldData.textStyleFontType
    field.textValueText = fieldData.textValueText
    field.textAlign = fieldData.textAlign
    if (field.type === 'date') {
      field.dateFormat = fieldData.dateFormat
      if (fieldData.textValueText !== null && validDate(fieldData.textValueText)) {
        const dateArray = fieldData.textValueText.split(/[-/년월일]/)
        const year = parseInt(dateArray[0])
        const month = parseInt(dateArray[1]) - 1
        const day = parseInt(dateArray[2])
        field.dateValue = dayjs(new Date(year, month, day))
      }
    }
    if (field.type === 'number') {
      field.addComma = fieldData.addComma
    }
  }
  if (field.type === 'signature') {
    field.value = fieldData.allowSignatureTypes.length === 2 ? 'USER' : fieldData.allowSignatureTypes[0]
  }

  return field
}

export const revertSignData = (pdfData, requesterFields, participants) => {
  const formattedPdfData = [...pdfData]
  formattedPdfData.forEach(item => {
    item.fields = []
    item.requesterFields = []
  })
  const fieldGroup = []
  let index = 0
  requesterFields.forEach((field) => {
    if (field.fieldType !== 'signature') index++
    const pdfW = formattedPdfData[field.locationPage - 1].pdfW
    const pdfH = formattedPdfData[field.locationPage - 1].pdfH
    const scale = formattedPdfData[field.locationPage - 1].scale
    formattedPdfData[field.locationPage - 1].requesterFields.push({
      ...revertRequestField(field, pdfW, pdfH, scale),
      fieldOrder: index
    })
  })
  participants.forEach(participant => {
    participant.fields.forEach(field => {
      if (field.fieldType === 'group') {
        field.count = participant.fields.filter(item => item.groupParentName === field.groupName).length
        fieldGroup.push(revertField(field, null, null, null, participant.signingOrder, participant.participantId))
      } else {
        const pdfW = formattedPdfData[field.locationPage - 1].pdfW
        const pdfH = formattedPdfData[field.locationPage - 1].pdfH
        const scale = formattedPdfData[field.locationPage - 1].scale
        formattedPdfData[field.locationPage - 1].fields.push(revertField(field, pdfW, pdfH, scale, participant.signingOrder, participant.participantId))
      }
    })
  })
  return {
    formattedPdfData,
    fieldGroup
  }
}

export const regenerateField = (prevField, prevPage, newPage) => {
  if (prevField.fieldType === 'group') return prevField
  return {
    ...prevField,
    x: Math.round(prevField.x / prevPage.pdfW / prevPage.scale * newPage.pdfW * newPage.scale),
    y: Math.round(prevField.y / prevPage.pdfH / prevPage.scale * newPage.pdfH * newPage.scale),
    w: Math.round(prevField.w / prevPage.pdfW / prevPage.scale * newPage.pdfW * newPage.scale),
    h: Math.round(prevField.h / prevPage.pdfH / prevPage.scale * newPage.pdfH * newPage.scale),
    active: false
  }
}

export const regenerateSignData = (prevPdfData, newPdfData) => {
  const prevIndex = prevPdfData.length
  const newIndex = newPdfData.length

  for (let i = 0; i < prevIndex; i++) {
    prevPdfData[i].requesterFields.forEach(field => {
      if (i < newIndex) {
        newPdfData[i].requesterFields.push(regenerateField(field, prevPdfData[i], newPdfData[i]))
      } else {
        newPdfData[newIndex - 1].requesterFields.push(regenerateField(field, prevPdfData[i], newPdfData[newIndex - 1]))
      }
    })

    prevPdfData[i].fields.forEach(field => {
      if (i < newIndex) {
        newPdfData[i].fields.push(regenerateField(field, prevPdfData[i], newPdfData[i]))
      } else {
        newPdfData[newIndex - 1].fields.push(regenerateField(field, prevPdfData[i], newPdfData[newIndex - 1]))
      }
    })
  }

  return {
    newPdfData
  }
}
