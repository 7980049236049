<template>
  <a-modal v-model:visible="visible" :footer="false">
    <div v-if="data !== null">
      <div v-for="(pdf, i) in data" :key="i">
        <!--        <div v-for="fields in pdf.fields" :key="fields.key" class="page">-->
        <!--          {{ pdf }}-->
        <!--        </div>-->
        <div class="page">
          {{ pdf }}
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const state = reactive({
      visible: false,
      data: null
    })

    const show = () => {
      state.visible = true
      state.data = store.state.documents.pdfData
    }

    const hide = () => {
      state.visible = false
      state.data = null
    }

    const activate = () => {
      if (state.visible) {
        hide()
      } else {
        show()
      }
    }

    return {
      ...toRefs(state),
      show,
      hide,
      activate
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding: 10px;
  border: 1px solid #BBB;
}
</style>
