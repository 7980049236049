<template>
  <VueDragResize
    :key="props.key"
    ref="signContainer"
    :aspectRatio="false"
    :class="props.isActive ? 'wrap-active' : ''"
    :h="init.h"
    :isDraggable="true"
    :isResizable="true"
    :keyboardEvent="props.keyboardEvent"
    :minh="65"
    :minw="65"
    :parentH="init.parentH"
    :parentLimitation="true"
    :parentW="init.parentW"
    :sticks="['br']"
    :style="{zIndex: props.isActive ? 999 : 99}"
    :w="init.w"
    :x="init.x"
    :y="init.y"
    class="signwrap"
    contentClass="box-shaddow"
    v-bind="$attrs"
    @activated="onActive"
    @clicked="clicked"
    @deactivated="onDeactivated"
    @dragstop="onDragStop"
    @resizestop="onResizeStop"
  >
    <div style="transform-origin: center;">
      <template v-if="props.isActive">
        <div class="sign-header"/>
      </template>
      <img :src="fullFilePath" class="image-box"/>
    </div>
    <template #stick>
      <arrows-alt-outlined style="transform: rotate(90deg);"/>
    </template>
  </VueDragResize>
</template>
<script setup>
import VueDragResize from '@/components/common/ucsDrag/Drag.vue'
import { computed, defineEmits, defineProps, nextTick, onMounted, ref, watch } from 'vue'
import { ArrowsAltOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { SIGNATURE_BYTE_URL } from 'config/config'

const props = defineProps(['key', 'style', 'class', 'signatureId', 'w', 'h', 'x', 'y', 'pdfW', 'pdfH', 'pdfScale', 'keyboardEvent', 'isActive'])
const emit = defineEmits(['update:x', 'update:y', 'update:w', 'update:h', 'update:isActive', 'update:isMoving', 'onActive', 'onActiveSingleField', 'onDeactivated'])
const signContainer = ref(null)
const store = useStore()

const fullFilePath = computed(() => {
  return SIGNATURE_BYTE_URL[process.env.NODE_ENV] + props.signatureId
})

const onActive = () => {
  emit('onActive')
  emit('update:isActive', true)
}

const onDeactivated = () => {
  emit('update:isActive', false)
  emit('onDeactivated')
}

const init = ref({
  w: props.w,
  h: props.h,
  x: props.x,
  y: props.y,
  parentW: Math.floor(props.pdfW * props.pdfScale),
  parentH: Math.floor(props.pdfH * props.pdfScale)
})

onMounted(() => {
  nextTick(() => {
    updateSize(init.value.w, init.value.h, init.value.x, init.value.y)
  })
})

const onResizeStop = ({
  left,
  top,
  width,
  height
}) => {
  updateSize(width, height, left, top)
}

const clicked = () => {
  emit('update:isMoving', true)

  if (props.isActive) return

  emit('onActiveSingleField')
  emit('update:isActive', true)
}

const onDragStop = ({
  left,
  top,
  width,
  height
}) => {
  updateSize(width, height, left, top)
  emit('update:isMoving', false)
}

const updateSize = (w, h, x, y) => {
  emit('update:w', w)
  emit('update:h', h)
  emit('update:x', x)
  emit('update:y', y)
  if (init.value.x !== x || init.value.y !== y || init.value.w !== w || init.value.h !== h) {
    store.dispatch('pushHistoryStorage')
  }
}

watch(() => props.x, () => {
  init.value.x = props.x
})
watch(() => props.y, () => {
  init.value.y = props.y
})

</script>

<style lang="less" scoped>
.signwrap {
  box-sizing: border-box;
  border: 2px dashed #CECECE;
  transform-origin: center;
}

.sign-header {
  position: absolute;
  top: -24px;
  left: -2px;
  width: calc(100% + 4px);
  height: 24px;
  background-color: #1890FF;
  border-radius: 4px 4px 0 0;
}

.image-box {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.wrap-active {
  border: 2px solid #1890FF;

  /deep/ .vdr-stick {
    border-radius: 50%;
    background: #1890FF;
    border-color: #1890FF;
    color: #FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .sign-close {
    position: absolute;
    right: 3px;
    top: -25px;
    color: #FFF;
    z-index: 5;
  }
}
</style>
