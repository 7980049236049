<template>
  <a-modal
    v-model:visible="visible"
    :bodyStyle="{ padding: 0, fontSize: '16px'}"
    :closable="false"
    :destroyOnClose="true"
    :width="650"
    class="signature-stamp-modal-wrapper ucs-modal">
    <a-spin :spinning="isShowLoading" size="large">
      <div class="modal-header bg-gray">
        <h2>내 사인 만들기</h2>
        <span class="modal-close" role="button" @click="visible = false">닫기</span>
      </div>
      <div class="modal-content">
        <a-tabs v-model:activeKey="activeKey" :tabBarStyle="{textAlign: 'center'}">
          <a-tab-pane :key="1">
            <WritePad ref="writePad"/>
          </a-tab-pane>
          <a-tab-pane :key="2">
            <GenerateSign type="signature" @selectSvg="onSelectSvg"/>
          </a-tab-pane>
          <a-tab-pane :key="3">
            <SignatureUpload ref="signatureUpload" type="signature"/>
          </a-tab-pane>
          <a-tab-pane :key="4" tab="내 사인">
            <SignaturesUsed type="sign" @selectSvg="onSelectSvg"/>
          </a-tab-pane>

          <template #renderTabBar>
            <ul class="tab-menu tab-round">
              <li v-for="item in tabs" :key="item.key" :class="{active: item.key === activeKey}" data-tab="signBox" role="tab" @click="activeKey = item.key">{{ item.name }}</li>
            </ul>
          </template>
        </a-tabs>
      </div>
    </a-spin>
    <template #footer>
      <a-button :loading="isSubmitting" class="btn" size="large" type="primary" @click="confirmSign">완료</a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import WritePad from 'components/signing/signaure/WritePad'
import { uploadSignature } from 'api/sign'
import GenerateSign from 'components/signing/signaure/GenerateSign'
import SignaturesUsed from 'components/signing/signaure/SignaturesUsed'
import SignatureUpload from 'components/signing/signaure/SignatureUpload'
import { getSvgSignature, signFromLink } from 'utils/helper'

const simpleTabs = [{
  key: 1,
  name: '그리기'
},
{
  key: 2,
  name: '사인 만들기'
},
{
  key: 3,
  name: '업로드'
}]

const fullTabs = [{
  key: 1,
  name: '그리기'
},
{
  key: 2,
  name: '사인 만들기'
},
{
  key: 3,
  name: '업로드'
},
{
  key: 4,
  name: '내 사인'
}]

export default {
  components: {
    WritePad,
    GenerateSign,
    SignaturesUsed,
    SignatureUpload
  },
  setup (props, { emit }) {
    const writePad = ref(null)
    const signatureUsed = ref(null)
    const signatureUpload = ref(null)
    const [isFromLink] = signFromLink()
    const state = reactive({
      field: null,
      visible: false,
      isShowLoading: false,
      activeKey: 1,
      isSubmitting: false,
      signSvg: null,
      tabs: [],
      hasUsed: false,
      isSave: true,
      isFromLink
    })
    const show = (hasUsed = true, isSave = true) => {
      state.isSave = isSave
      state.tabs = hasUsed ? fullTabs : simpleTabs
      state.visible = true
    }
    const confirmSign = async () => {
      let signSvg = ''
      if (state.activeKey === 1) {
        signSvg = writePad.value.getSignSvg()
      }
      if (state.activeKey === 2) {
        signSvg = state.signSvg
      }
      if (state.activeKey === 3) {
        const imageBase64 = signatureUpload.value.getCropedImage()
        if (imageBase64 === null) {
          signSvg = null
        } else {
          signSvg = getSvgSignature('sign', imageBase64)
        }
      }
      if (state.activeKey === 4) {
        if (state.signSvg !== null) {
          emit('finishSign', state.signSvg)
          state.visible = false
        }
        return false
      }
      if (signSvg === null) {
        return false
      }
      state.isSubmitting = true
      uploadSignature({
        isSave: state.isSave,
        code: signSvg,
        type: 'sign'
      }).then(res => {
        emit('finishSign', res.result)
        state.visible = false
      }).finally(() => {
        state.isSubmitting = false
      })
    }

    const onSelectSvg = (svg) => {
      state.signSvg = svg
    }

    return {
      show,
      confirmSign,
      writePad,
      onSelectSvg,
      signatureUsed,
      signatureUpload,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.signature-stamp-modal-wrapper {
  .btn {
    margin-bottom: 10px;
    width: 180px;
  }
}

</style>
