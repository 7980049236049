<template>
  <a-modal
    v-model:visible="visible"
    :bodyStyle="{ padding: 0, fontSize: '16px'}"
    :closable="false"
    :destroyOnClose="true"
    :maskClosable="false"
    :width="650"
    class="signature-stamp-modal-wrapper">
    <a-spin :spinning="isShowLoading" size="large">
      <div class="modal-header bg-gray">
        <h2>내 도장 만들기</h2>
        <span class="modal-close" role="button" @click="visible = false">닫기</span>
      </div>
      <div class="modal-content">
        <a-tabs v-model:activeKey="activeKey" :tabBarStyle="{textAlign: 'center'}">
          <a-tab-pane :key="1" tab="일반 도장">
            <GenerateSign ownerType="personal" type="stamp" @selectSvg="onSelectSvg"/>
          </a-tab-pane>
          <a-tab-pane :key="2" tab="법인 도장">
            <GenerateSign ownerType="corporate" type="stamp" @selectSvg="onSelectSvg"/>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="업로드">
            <SignatureUpload ref="signatureUpload" type="stamp" @iamgeChanged="iamgeChanged"/>
          </a-tab-pane>
          <a-tab-pane :key="4" tab="내 도장">
            <SignaturesUsed type="stamp" @selectSvg="onSelectSvg"/>
          </a-tab-pane>

          <template #renderTabBar>
            <ul class="tab-menu tab-round">
              <li v-for="item in tabs" :key="item.key" :class="{active: item.key === activeKey}" data-tab="signBox" role="tab" @click="activeKey = item.key">{{ item.name }}</li>
            </ul>
          </template>
        </a-tabs>
      </div>
    </a-spin>
    <template #footer>
      <a-button :loading="isSubmitting" class="btn" size="large" type="primary" @click="confirmSign">완료</a-button>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import GenerateSign from 'components/signing/signaure/GenerateSign'
import { uploadSignature } from 'api/sign'
import SignaturesUsed from 'components/signing/signaure/SignaturesUsed'
import SignatureUpload from 'components/signing/signaure/SignatureUpload'
import { getSvgSignature } from 'utils/helper'

const simpleTabs = [
  {
    key: 1,
    name: '일반도장'
  },
  {
    key: 2,
    name: '법인도장'
  },
  {
    key: 3,
    name: '업로드'
  }]

const fullTabs = [
  {
    key: 1,
    name: '일반도장'
  },
  {
    key: 2,
    name: '법인도장'
  },
  {
    key: 3,
    name: '업로드'
  },
  {
    key: 4,
    name: '내 도장'
  }]

export default {
  components: {
    GenerateSign,
    SignaturesUsed,
    SignatureUpload
  },
  setup (props, { emit }) {
    const signatureUpload = ref(null)
    const state = reactive({
      field: null,
      visible: false,
      isShowLoading: false,
      activeKey: 1,
      isSubmitting: false,
      stampSvg: null,
      hasUsed: false,
      isSave: false,
      tabs: []
    })
    const show = (hasUsed = true, isSave = true) => {
      state.isSave = isSave
      state.tabs = hasUsed ? fullTabs : simpleTabs
      state.visible = true
    }
    const confirmSign = () => {
      if (state.activeKey === 4) {
        if (state.stampSvg !== null) {
          emit('finishSign', state.stampSvg)
          state.visible = false
        }
        return false
      }
      if (state.activeKey === 3) {
        const imageBase64 = signatureUpload.value.getCropedImage()
        if (imageBase64 === null) {
          state.stampSvg = null
        } else {
          state.stampSvg = getSvgSignature('stamp', imageBase64)
        }
      }
      if (state.stampSvg === null) {
        return false
      }
      state.isSubmitting = true
      uploadSignature({
        isSave: state.isSave,
        code: state.stampSvg,
        type: 'stamp'
      }).then(res => {
        emit('finishSign', res.result)
        state.visible = false
      }).finally(() => {
        state.isSubmitting = false
      })
    }
    const onSelectSvg = (stampSvg) => {
      state.stampSvg = stampSvg
    }
    const iamgeChanged = () => {
      state.stampSvg = 'changed'
    }
    return {
      show,
      confirmSign,
      onSelectSvg,
      signatureUpload,
      iamgeChanged,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.signature-stamp-modal-wrapper {
  .modal-header {
    padding: 20px;

    .modal-close {
      margin: 24px;
    }
  }

  .modal-content {
    border: none;
  }
}
</style>
