<template>
  <div class="side-bar left-bar" @keyup.esc="clearCurrentField">
    <a-collapse v-model:activeKey="activeKey" class="requester-collapse" expand-icon-position="right" style="margin-bottom: 10px;">
      <a-collapse-panel key="1" header="추가 내용 입력하기">
        <div class="box-body">
          <p>문서에 입력한 내용을 추가하여 서명요청을 합니다. 요청이 완료된 이후 입력한 내용은 수정할 수 없습니다.</p>
          <div class="form-group">
            <div class="input-group form-control form-ico" role="button"
                 @click="createFloatField({type: 'signature', formType: 'requesterFields', icon: 'ico-user', text: '사인/도장', value: 'USER', signingOrder: 0 })">
              <div class="input-group-addon">
                <i class="ico ico-stamp-b"></i>
              </div>
              <span class="sign-btn">사인/도장 입력하기</span>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group form-control form-ico" role="button" @click="createFloatField({type: 'text', formType: 'requesterFields'})">
              <div class="input-group-addon">
                <i class="ico ico-s ico-text"></i>
              </div>
              <span class="sign-btn">텍스트 입력하기</span>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group form-control form-ico" role="button" @click="createFloatField({type: 'checkbox', formType: 'requesterFields'})">
              <div class="input-group-addon">
                <i class="ico ico-s ico-check"></i>
              </div>
              <span class="sign-btn">체크박스 입력하기</span>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!--    <div class="sign-group" v-if="signers.length > 0">-->
    <!--      <SignerCard v-for="signer in signers" :key="signer.signingOrder" :signer="signer" @selectField="onSelectField" @clickInfo="onClickInfo" />-->
    <!--      &lt;!&ndash; //다섯번째 서명 &ndash;&gt;-->
    <!--    </div>-->
    <div class="sign-group">
      <SignerCard :signers="signers" @clickInfo="onClickInfo" @selectField="onSelectField"/>
    </div>
    <!-- //서명 리스트 그룹 -->
  </div>
  <div v-if="!guideToolTip2" class="fixed-tooltip">
    <div class="txt">
      <span>참여자가 입력할 필드를 추가하세요.</span>
      <CloseOutlined @click="closeGuideTooltip2"/>
    </div>
    <CaretDownOutlined/>
  </div>
  <div class="requesterInputs">
    <teleport to="body">
      <div v-if="currentField && currentField.type === 'signature'" :style="fieldStyle" class="requester-field ucs-sign">
        <SignField :signField="currentField" :signerInfo="currentField" style="pointer-events: none" @click="onClick"/>
      </div>
      <div v-if="currentField && currentField.type === 'text'" :style="fieldStyle" class="requester-field requester-input">
        <UcsTextarea :signField="currentField"/>
      </div>
      <div v-if="currentField && currentField.type === 'checkbox'" :style="fieldStyle" class="requester-field requester-checkbox">
        <UcsCheckbox :signField="currentField"/>
      </div>
    </teleport>
  </div>
  <div class="fields">
    <teleport to="body">
      <div v-if="currentField && currentField.type === 'signature'" :style="fieldStyle" class="requester-field ucs-sign">
        <SignField :signField="currentField" :signerInfo="currentField" style="pointer-events: none" @click="onClick"/>
      </div>
      <div v-if="currentField && currentField.type === 'text'" :style="fieldStyle" class="requester-field requester-input">
        <UcsTextarea :placeholder="'텍스트'" :signField="currentField"/>
      </div>
      <div v-if="currentField && currentField.type === 'checkbox'" :style="fieldStyle" class="requester-field requester-checkbox">
        <UcsCheckbox :signField="currentField"/>
      </div>
      <div v-if="currentField && currentField.type === 'date'" :style="fieldStyle" class="requester-field requester-date">
        <UcsTextarea :placeholder="'날짜'" :signField="currentField"/>
      </div>
      <div v-if="currentField && currentField.type === 'number'" :style="fieldStyle" class="requester-field requester-number">
        <UcsTextarea :placeholder="'숫자'" :signField="currentField"/>
      </div>
    </teleport>
  </div>
</template>

<script>
import { computed, createVNode, onMounted, onUnmounted, reactive, toRefs, watchEffect } from 'vue'
import { useMousemove } from 'use/sign/useMousemove'
import UcsTextarea from 'components/signing/createForm/UcsTextarea'
import UcsCheckbox from 'components/signing/createForm/UcsCheckbox'
import SignerCard from 'components/signing/createForm/SignerCard'
import SignField from 'components/signing/createForm/SignField'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { CaretDownOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { setNotice } from 'api/user'

export default {
  components: {
    UcsTextarea,
    UcsCheckbox,
    // SignerCard,
    SignerCard,
    SignField,
    CloseOutlined,
    CaretDownOutlined
  },
  emits: ['clickSign'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      currentField: null,
      // signers: store.state.documents.signers,
      signTypeRecords: [], // {pageIndex, signingOrder, signType}
      isAllSignFilled: false,
      activeKey: 1,
      guideToolTip2: store.state.user.userInfo.notifications.guide2
    })

    const {
      mouseLocation,
      startWatchMouse,
      stopWatchMouse
    } = useMousemove()
    const fieldStyle = computed(() => {
      return {
        zIndex: 9999,
        left: mouseLocation.value.x + 2 + 'px',
        top: mouseLocation.value.y + 2 + 'px'
      }
    })

    const setPdfSignTypes = (signType, signingOrder) => {
      const pdfData = [...store.state.documents.pdfData]
      pdfData.forEach(item => {
        item.fields.forEach(field => {
          if (field.type === 'signature' && field.signingOrder === signingOrder) field.value = signType
        })
      })
      store.dispatch('setPdfData', pdfData)
    }

    const checkSignType = (currentField) => {
      if (state.signTypeRecords.length === 0) return true
      const curSignerFields = state.signTypeRecords.filter(item => item.signingOrder === currentField.signingOrder)
      const hasCreated = curSignerFields.findIndex(item => item.value === currentField.value) !== -1
      if (curSignerFields.length !== 0 && !hasCreated) {
        Modal.confirm({
          title: '서명/도장 지침',
          icon: createVNode(ExclamationCircleOutlined),
          content: '참가자당 하나의 유형만 설정할 수 있습니다.모든 서명 방법을 서명으로 변경하시겠습니까?',
          okText: '변경하기',
          cancelText: '취소',
          onOk () {
            setPdfSignTypes(currentField.value, currentField.signingOrder)
          }
        })
        return false
      }
      return true
    }

    const getCurrentField = () => {
      return state.currentField
    }

    const clearCurrentField = () => {
      state.currentField = null
    }

    const createFloatField = (info) => {
      if (state.currentField) return false

      state.currentField = {
        ...info,
        x: mouseLocation.value.x,
        y: mouseLocation.value.y
      }
      startWatchMouse()
    }

    const onSelectField = (signField) => {
      if (checkSignType(signField)) {
        createFloatField(signField)
      }
    }

    const onClickInfo = (info) => {
      createFloatField(info)
    }

    const clickEsc = event => {
      if (event.code === 'Escape' && state.currentField) {
        clearCurrentField()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', clickEsc)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', clickEsc)
    })

    watchEffect(() => {
      const signFields = []
      const pdfPage = store.state.documents.pdfData
      pdfPage.forEach(item => {
        const temp = item.fields.filter(field => {
          return field.type === 'signature'
        })
        signFields.push(...temp)
      })
      const signers = []
      signFields.forEach(item => {
        signers.push(item.signingOrder)
      })
      state.signTypeRecords = signFields
    })

    const onClick = (event) => {
      emit('clickSign', event)
    }

    const closeGuideTooltip2 = () => {
      setNotice({
        type: 'guide2'
      })
        .then(() => {
          store.dispatch('userInfo').then(() => {
            state.guideToolTip2 = true
          })
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
    }

    return {
      mouseLocation,
      startWatchMouse,
      stopWatchMouse,
      fieldStyle,
      getCurrentField,
      clearCurrentField,
      onSelectField,
      onClickInfo,
      createFloatField,
      onClick,
      closeGuideTooltip2,
      ...toRefs(state),
      ...toRefs(store.state.documents)
    }
  }
}
</script>

<style lang="less" scoped>
.requester-field {
  position: fixed;
}

.requester-input {
  width: 170px;
  height: 14px;
}

.requester-checkbox {
  width: 20px;
  height: 20px;
}

.ucs-sign {
  width: 65px;
  height: 65px;
}

.fixed-tooltip {
  position: absolute;
  top: 528px;
  left: 338px;
  text-align: center;
  border-radius: 10rem;
  background: #27d0b0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.7rem 1.5rem;
  word-break: keep-all;
  z-index: 1000;

  .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon-close {
      padding: 0.5rem;
      margin: -0.9rem -1rem -1rem 0.5rem;
    }
  }

  .anticon-caret-down {
    position: absolute;
    top: 9px;
    left: -12px;
    line-height: 0 !important;
    color: #27d0b0;
    font-size: 20px;
    transform: scale(3, 1) rotate(90deg);
  }
}

@media screen and (max-width: 768px ) {
  .fixed-tooltip {
    display: none;
  }
}

</style>
