<template>
  <div class="default-size move-form" :class="cls" :style="{zIndex: active ? 999 : 10}">
    <FontTip v-show="active" v-model:fontSize="textStyleFontSize" v-model:fontType="textStyleFontType" v-model:required="required" ref="wrapper" :isShowRequest="isShowRequest"
             @onFontTipSelected="onFontTipSelected"/>
    <button v-if="active" type="button" class="e-close" @click="onClickRemove">
      <i class="ico ico-g-delete">닫기</i>
    </button>
    <textarea
      class="e-control e-txt form-item"
      :class="textClass"
      :style="fontStyle"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('update:value', $event.target.value)"
      @focus="isShowTip = true; isFocus = true"
      @blur="isFocus = false"
      :readonly="isReadonly"
      ref="textInput"
    ></textarea>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs, watchEffect } from 'vue'
import FontTip from 'components/signing/createForm/FontTip'

export default {
  props: ['active', 'signField', 'value', 'isDragging', 'placeholder'],
  components: { FontTip },
  setup (props, { emit }) {
    const state = reactive({
      placeholder: props.placeholder || '텍스트',
      isShowTip: false,
      textStyleFontSize: 12,
      textStyleFontType: 'Noto Sans KR',
      required: false,
      isFocus: false
    })
    const cls = computed(() => {
      const order = props.signField.signingOrder || 1
      const name = props.signField.formType === 'requesterFields' ? 'sign-basic' : `sign${order}`
      return {
        'e-sure': state.required,
        [name]: true
      }
    })
    const onClickRemove = () => {
      emit('onClickRemove')
    }
    const fontStyle = computed(() => {
      return {
        lineHeight: `${state.textStyleFontSize}px`,
        fontSize: `${state.textStyleFontSize}px`
      }
    })

    const isShowRequest = computed(() => {
      return ['requesterFields', 'docEdit'].includes(props.signField.formType)
    })

    const isReadonly = computed(() => {
      if (props.signField.formType) {
        return !['requesterFields', 'docEdit'].includes(props.signField.formType)
      } else {
        return false
      }
    })

    watchEffect(() => {
      emit('update:fontSize', state.textStyleFontSize)
      emit('update:fontType', state.textStyleFontType)
      emit('update:required', state.required)
    })

    const textClass = computed(() => {
      return { focus: state.isFocus && !props.isDragging }
    })

    const textInput = ref(null)

    watchEffect(() => {
      if (props.active) textInput.value.focus()
    })

    const onFontTipSelected = () => {
      textInput.value.focus()
    }

    return {
      onClickRemove,
      cls,
      fontStyle,
      isShowRequest,
      textClass,
      isReadonly,
      textInput,
      onFontTipSelected,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.move-form {
  width: 100%;
  height: 100%;
  z-index: 99;

  .e-txt {
    color: #000;
  }

  .focus {
    cursor: text;
  }
}

.form-item {
  padding: 0;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: hidden;
}
</style>
