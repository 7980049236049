<template>
  <div
    class="tooltip white top"
    style="position: absolute; left: 50%; top: -10px; transform: translate(-50%, -100%);">
    <div class="form-wrap">
      <div class="form-line" v-if="!isShowRequest">
        <a-row>
          <a-col span="19">필수여부</a-col>
          <a-col>
            <a-switch v-model:checked="isRequired" size="small" @change="$emit('update:required', isRequired)"/>
          </a-col>
        </a-row>
      </div>
      <div class="form-line">
        <a-row>
          <a-col class="form-tit" span="10">글자크기</a-col>
          <a-col span="14">
            <a-select :dropdownStyle="{zIndex: 10000}" ref="fontSizeSelectWrap" v-model:value="textStyleFontSize" style="width: 100%" @change="onFontSizeChange"
                      :getPopupContainer="() => fontSizeSelectWrap.$el" @select="onFontTipSelected">
              <a-select-option v-for="option in fontSizeOptions" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
      <div class="form-line">
        <a-row>
          <a-col class="form-tit" span="10">글씨체</a-col>
          <a-col span="14">
            <a-select :dropdownStyle="{zIndex: 10000}" ref="fontTypeSelectWrap" v-model:value="textStyleFontType" style="width: 100%" @change="onFontTypeChange"
                      :getPopupContainer="() => fontTypeSelectWrap.$el" @select="onFontTipSelected">
              <a-select-option v-for="option in fontTypeOptions" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
      <div class="form-line" v-if="!isEdittingDoc">
        <a-row>
          <a-col class="form-tit" span="10">필드이름<br/>(선택)
            <a-tooltip placement="top" :color="'#FFF'" overlayClassName="signer-tooltip" :zIndex="10000">
              <button type="button" class="btn-info">?</button>
              <template #title>
                <p class="content">
                  필드이름을 지정하면 대량전송 또는 모바일 서명요청 시 해당 필드에 입력할 정보를 간편하게 채워넣을 수 있습니다.
                  실제 계약서에 입력할 내용은 텍스트 필드에 직접 입력해 주세요.
                </p>
              </template>
            </a-tooltip>
          </a-col>
          <a-col span="14" style="display: flex;">
            <a-input v-model:value="dataTitle" ref="titleInput" @change="$emit('update:title', dataTitle)" placeholder="필드이름" style="width: 100%; margin: auto;" @click="titleFocus"
                     @mousedown="handleInputOperation" :lazy="false"/>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'

export default {
  props: ['fontSize', 'fontType', 'required', 'isShowRequest', 'title', 'isEdittingDoc'],
  setup (props, { emit }) {
    const fontSizeSelectWrap = ref(null)
    const fontTypeSelectWrap = ref(null)
    const titleInput = ref(null)
    const state = reactive({
      fontSizeOptions: [
        {
          value: 6,
          name: '6pt'
        },
        {
          value: 7,
          name: '7pt'
        },
        {
          value: 8,
          name: '8pt'
        },
        {
          value: 9,
          name: '9pt'
        },
        {
          value: 10,
          name: '10pt'
        },
        {
          value: 11,
          name: '11pt'
        },
        {
          value: 12,
          name: '12pt'
        },
        {
          value: 13,
          name: '13pt'
        },
        {
          value: 14,
          name: '14pt'
        },
        {
          value: 15,
          name: '15pt'
        },
        {
          value: 16,
          name: '16pt'
        },
        {
          value: 17,
          name: '17pt'
        },
        {
          value: 18,
          name: '18pt'
        },
        {
          value: 24,
          name: '24pt'
        },
        {
          value: 30,
          name: '30pt'
        },
        {
          value: 48,
          name: '48pt'
        },
        {
          value: 60,
          name: '60pt'
        }
      ],
      textStyleFontSize: props.fontSize,
      fontTypeOptions: [
        {
          value: 'Noto Sans KR',
          name: 'Noto Sans KR'
        }
      ],
      textStyleFontType: 'Noto Sans KR',
      isRequired: props.required,
      dataTitle: props.title
    })

    const onFontSizeChange = value => {
      emit('update:fontSize', value)
    }

    const onFontTypeChange = value => {
      emit('update:fontType', value)
    }

    const onFontTipSelected = () => {
      emit('onFontTipSelected')
    }

    const titleFocus = () => {
      titleInput.value.focus()
    }

    const handleInputOperation = event => {
      event.stopPropagation()
    }

    return {
      onFontSizeChange,
      onFontTypeChange,
      fontSizeSelectWrap,
      fontTypeSelectWrap,
      titleInput,
      titleFocus,
      onFontTipSelected,
      handleInputOperation,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.select-top {
  z-index: 999;
}
</style>
