<div
        :id="fieldKey"
        :class="`${(active || isActive) ? 'active' : 'inactive'} ${contentClass ? contentClass : ''}`"
        :style="positionStyle"
        class="vdr"
        @mousedown="bodyDown($event)">
    <div ref="container" :style="sizeStyle" class="content-container">
        <slot></slot>
    </div>
    <div
            v-for="stick in sticks"
            :class="['vdr-stick-' + stick, isResizable ? '' : 'not-resizable']"
            :style="vdrStick(stick)"
            class="vdr-stick"
            @mousedown.stop.prevent="stickDown(stick, $event)"
            @touchstart.stop.prevent="stickDown(stick, $event)">
        <slot name="stick"></slot>
    </div>
</div>
