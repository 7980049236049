<template>
  <div class="sign-signature-wrapper" :class="[signNumClass, requiredClass]">
    <!-- tooltip -->
    <div class="tooltip top sm sign-tip" :class="signNumClass" style="width:68px; left:calc(50% - 34px);position:absolute;bottom:100%;">
      {{ signTip }}
    </div>
    <!--<div class="sign-e-control e-sign" ><i class="sign-ico" :class="signTypeClass"></i></div>-->
  </div>
</template>

<script>
import { computed, reactive, toRefs, watchEffect } from 'vue'

export default {
  props: ['signField', 'active', 'required'],
  setup (props) {
    const state = reactive({
      isRequester: props.signField.formType === 'requesterFields',
      isRequired: props.required,
      count: 0
    })
    const signTip = computed(() => {
      const types = {
        user: '사인/도장',
        sign: '사인',
        stamp: '도장'
      }
      return types[props.signField.value.toLowerCase()]
    })
    const signNumClass = computed(() => {
      return `sign${props.signField.signingOrder}`
    })

    const requiredClass = computed(() => {
      return state.isRequired ? 'e-sure' : ''
    })

    const signTypeClass = computed(() => {
      const types = {
        USER: 'sign-user',
        SIGN: 'sign-sign',
        STAMP: 'sign-stamp'
      }
      return types[props.signField.value]
    })

    watchEffect(() => {
      state.isRequired = props.required
    })

    return {
      signTip,
      signNumClass,
      signTypeClass,
      requiredClass,
      ...toRefs(props),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.sign-tip {
  text-align: center;
  user-select: none;
  cursor: default;
}

/* sign0(requester) color */
.sign-signature-wrapper.sign0 {
  background: #5e5e5e5b;
}

.sign0 .sign-e-control {
  border-color: #5e5e5e;
}

.sign0 .sign-e-control:not(.e-sign) {
  background: #d6d6d6;
}

/* sign1 color */
.sign-signature-wrapper.sign1 {
  background: #27d0b15b;
}

.sign1 .sign-e-control {
  border-color: #27d0b0;
}

.sign1 .sign-e-control:not(.e-sign) {
  background: #eafaf9;
}

.sign1 .sign-sign {
  background-position: -50px 0;
}

.sign1 .sign-stamp {
  background-position: -100px 0;
}

/* sign2 color */

.sign-signature-wrapper.sign2 {
  background: #ffca105b;
}

.sign2 .sign-e-control {
  border-color: #ffca10;
}

.sign2 .sign-e-control:not(.e-sign) {
  background: #fdf7dc;
}

.sign2 .sign-user {
  background-position: -150px 0;
}

.sign2 .sign-sign {
  background-position: -200px 0;
}

.sign2 .sign-stamp {
  background-position: -250px 0;
}

/* sign3 color */
.sign-signature-wrapper.sign3 {
  background: #6dacf75b;
}

.sign3 .sign-e-control {
  border-color: #6dacf7;
}

.sign3 .sign-e-control:not(.e-sign) {
  background: #edf5fe;
}

.sign3 .sign-user {
  background-position: 0 -50px;
}

.sign3 .sign-sign {
  background-position: -50px -50px;
}

.sign3 .sign-stamp {
  background-position: -100px -50px;
}

/* sign4 color */
.sign-signature-wrapper.sign4 {
  background: #cd75ce5b;
}

.sign4 .sign-e-control {
  border-color: #cd75ce;
}

.sign4 .sign-e-control:not(.e-sign) {
  background: #f8ebf8;
}

.sign4 .sign-user {
  background-position: -150px -50px;
}

.sign4 .sign-sign {
  background-position: -200px -50px;
}

.sign4 .sign-stamp {
  background-position: -250px -50px;
}

/* sign5 color */
.sign-signature-wrapper.sign5 {
  background: #a5d46e5b;
}

.sign5 .sign-e-control {
  border-color: #a5d46e;
}

.sign5 .sign-e-control:not(.e-sign) {
  background: #f3faee;
}

.sign5 .sign-user {
  background-position: 0 -100px;
}

.sign5 .sign-sign {
  background-position: -50px -100px;
}

.sign5 .sign-stamp {
  background-position: -100px -100px;
}

.sign-signature-wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: move;
}

.sign-e-control {
  display: inline-block;
  cursor: move;
}

.tooltip.sm {
  width: auto;
  padding: 7px 12px;
  white-space: nowrap;
  cursor: move;
}
</style>
