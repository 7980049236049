<template>
  <div
    class="vue--picture-cropper__wrap"
    :style="boxStyle"
  >
    <img
      class="vue--picture-cropper__img"
      :src="img"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

export let cropper

const CropTool = defineComponent({
  name: 'CropTool',
  props: {
    boxStyle: {
      type: Object,
      required: false,
      default: () => ({})
    },
    img: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      cropper: null,
      mimeType: ''
    }
  },
  watch: {
    img (newVal, oldVal) {
      if (!this.cropper) {
        this.init()
        return false
      }

      try {
        this.cropper.replace(this.img, true)
        this.getImgSuffix()
      } catch (e) {
        console.log(e)
      }
    }
  },
  beforeUnmount () {
    if (this.cropper) {
      this.cropper.destroy()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.$nextTick()

      const CHECK = setInterval(() => {
        const IMG_DOM = document.querySelector('.vue--picture-cropper__img')

        if (IMG_DOM) {
          try {
            this.cropper = new Cropper(IMG_DOM, this.options)
            clearInterval(CHECK)
            this.updateInstance()
            this.getImgSuffix()
          } catch (e) {
            console.log(e)
          }
        }
      }, 10)
    },

    updateInstance () {
      cropper = this.cropper
      cropper.getDataURL = this.getDataURL
      cropper.getBlob = this.getBlob
    },

    getImgSuffix () {
      const IMG_ARR = this.img.split(',')
      const IMG_INFO = IMG_ARR[0]
      const IMG_MIME_TYPE = IMG_INFO.replace(/data:(.*);base64/, '$1')
      this.mimeType = IMG_MIME_TYPE
    },

    getDataURL (options = {}) {
      try {
        const RESULT = this.cropper.getCroppedCanvas(options).toDataURL(this.mimeType)
        return RESULT
      } catch (e) {
        return ''
      }
    },

    getBlob (options) {
      const DATA_URL = cropper.getDataURL()

      const IMG_ARR = DATA_URL.split(',')
      const IMG_CONTENT = IMG_ARR[1].substring(0, IMG_ARR[1].length - 2)

      const A2B = (window).atob(IMG_CONTENT)
      let n = A2B.length
      const U8_ARR = new Uint8Array(n)
      while (n--) {
        U8_ARR[n] = A2B.charCodeAt(n)
      }

      return new Blob([U8_ARR], {
        type: this.mimeType
      })
    }

  }
})

export default CropTool
</script>

<style>

.vue--picture-cropper__wrap {
  width: 100%;
  height: 100%;
  margin: 0;
}

.vue--picture-cropper__img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

</style>
