<template>
  <ul :class="{ 'sign-preivew': type === 'sign' }" class="preview">
    <li
      v-for="(svg, index) in svgs"
      :key="index"
      :class="{
        selected: currentSelected === index
      }"
      class="svg-item"
      @click="onSelectStamp(index)"
    ><img :src="BASE_URL + svg.signatureId" class="item-img"/>
    </li>
  </ul>

  <div v-show="hasMore" class="btns">
    <a-button @click="loadMore">더 보기</a-button>
  </div>
</template>

<script>
import { SIGNATURE_BYTE_URL } from 'config/config'

export default {
  props: ['type', 'svgs', 'hasMore'],
  emits: ['selectSvg', 'onClickLoadMore'],
  name: 'svgPreview',
  data () {
    return {
      currentSelected: -1,
      BASE_URL: SIGNATURE_BYTE_URL[process.env.NODE_ENV]
    }
  },
  methods: {
    onSelectStamp (index) {
      this.currentSelected = index
      this.$emit('selectSvg', this.svgs[index])
    },
    loadMore () {
      this.$emit('onClickLoadMore')
    }
  }
}
</script>

<style lang="less" scoped>

.preview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;

  .svg-item {
    box-sizing: border-box;
    background: #FFF;
    cursor: pointer;
    font-size: 0;
    @media (hover: hover) {
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .item-img {
      width: 100%;
      border: 1px solid #CCC;
      box-sizing: border-box;
    }
  }

  .selected {
    background: rgba(0, 0, 0, 0.1);
  }
}

.sign-preivew {
  grid-template-columns: repeat(2, 1fr);
}

.btns {
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .preview {
    grid-template-columns: repeat(2, 1fr);
  }

  .sign-preivew {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
