<template>
  <a-modal
    v-model:visible="visible"
    :width="520"
    :footer="null"
    :closable="false"
    :bodyStyle="{ padding: 0, fontSize: '16px', minHeight: '480px'}"
    class="attachment-modal-wrapper">
    <div class="modal-header">
      <h2>{{ userName }} 님에게 첨부파일 요청
        <a-tooltip placement="right" :color="'#FFF'" overlayClassName="signer-tooltip">
          <button type="button" class="btn-info">?</button>
          <template #title>
            <p class="content">
              서명 참여자에게 계약서 이외 추가로 받아야하는 자료가 있을 때 사용해 보세요.
              꼭 받아야하는 자료는 필수여부 스위치를 켜주세요.
            </p>
          </template>
        </a-tooltip>
      </h2>
      <span role="button" class="modal-close" @click="visible = false">닫기</span>
    </div>
    <div class="modal-content bg-gray">
      <div class="panel-wrap">
        <div class="panel" v-for="(item, index) in attachments" :key="index">
          <div class="panel-head">
            <div class="sub-tit">
              <h3>파일 요청 {{ index + 1 }}</h3>
              <div class="s-right">
                <div class="switch-box">
                  <label for="inputSwitch1" class="switch-text">필수여부</label>
                  <a-switch v-model:checked="item.required" size="small"/>
                </div>
                <button type="button" class="btn-delete link text-gray" @click="remove(index)">삭제</button>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="sort" class="form-tit">파일 종류</label>
              <a-input :maxlength="30" placeholder="30자 이하로 입력 (예:통장사본)" v-model:value="item.attachmentType"></a-input>
            </div>
            <!-- //파일종류 -->
            <div class="form-group">
              <label for="info" class="form-tit">추가 안내 사항</label>
              <a-textarea :rows="5" placeholder="100자 이하로 입력 (예:개인사업자의 경우에 한합니다.)" :maxlength="100" v-model:value="item.description"></a-textarea>
            </div>
          </div>
        </div>
        <div class="btn-center">
          <button type="button" class="btn btn-lg" @click="add">
                    <span class="ico-plus">
                        <svg data-icon="plus" width="16px" height="16px" fill="#262626" aria-hidden="true" viewBox="64 64 896 896" focusable="false">
                            <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                        </svg>
                    </span>파일 요청 추가
          </button>
        </div>
      </div>
      <!-- //panel-wrap -->
      <div class="btn-horizon">
        <a-button type="primary" @click="confirmAttachments" :disabled="isDisableConfirm">설정하기</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'

const attachment = {
  attachmentType: '',
  description: '',
  required: false
}
export default {
  props: ['userName'],
  setup (props, { emit }) {
    const state = reactive({
      visible: false,
      attachments: [
        {
          attachmentType: '',
          description: '',
          required: false
        }
      ]
    })
    const show = (attachments = null) => {
      if (attachments && attachments.length > 0) state.attachments = attachments
      state.visible = true
    }
    const add = () => {
      state.attachments.push({ ...attachment })
    }
    const remove = (index) => {
      if (state.attachments.length === 0) return false
      state.attachments.splice(index, 1)
    }
    const confirmAttachments = () => {
      emit('confirmAttachments', state.attachments)
      state.visible = false
    }
    const isDisableConfirm = computed(() => {
      return state.attachments.findIndex(item => item.attachmentType.trim() === '') !== -1
    })
    return {
      show,
      add,
      remove,
      confirmAttachments,
      isDisableConfirm,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.attachment-modal-wrapper {
  .modal-header {
    padding: 2rem;
    border: none;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-content {
    border: none;
  }

  .panel-wrap {
    height: 450px;
    overflow: auto;
  }

  .btn-horizon {
    padding: 20px 0;
  }
}
</style>
