<template>
  <Drag
    ref="drager"
    :aspectRatio="props.aspectRatio"
    :fieldKey="props.fieldKey"
    :h="state.h"
    :isActive="props.isActive"
    :isDraggable="props.isControlDrag ? !props.isActive : true"
    :isResizable="props.resizable || true"
    :keyboardEvent="props.keyboardEvent"
    :minh="state.minHeight"
    :minw="state.minWidth"
    :parentH="state.parentH"
    :parentLimitation="true"
    :parentW="state.parentW"
    :stickSize="10"
    :sticks="props.resizable ? ['br'] : []"
    :w="state.w"
    :x="state.x"
    :y="state.y"
    contentClass="box-shadow"
    @activated="onActive"
    @clicked="clicked"
    @deactivated="onDeactivated"
    @dragging="onDragging"
    @dragstop="onDragEnd"
    @resizestop="onResizeStop"
  >
    <slot :isDragging="state.isDragging"></slot>
  </Drag>
</template>

<script setup>
import { defineEmits, defineProps, reactive, ref, watch } from 'vue'
import Drag from '@/components/common/ucsDrag/Drag.vue'
import { useStore } from 'vuex'

const emit = defineEmits(['update:isMoving', 'update:x', 'update:y', 'update:w', 'update:h', 'update:isMoving', 'update:isActive', 'onActive', 'onActiveSingleField', 'onDeactivated', 'onDragging'])
const props = defineProps(['fieldKey', 'x', 'y', 'w', 'h', 'initW', 'initH', 'isActive', 'isMoving', 'resizable', 'isControlDrag', 'pdfW', 'pdfH', 'pdfScale', 'minW', 'minH', 'aspectRatio', 'type', 'keyboardEvent'])
const store = useStore()

const state = reactive({
  x: props.x,
  y: props.y,
  w: props.w,
  h: props.h,
  parentW: Math.floor(props.pdfW * props.pdfScale),
  parentH: Math.floor(props.pdfH * props.pdfScale),
  minWidth: props.type === 'signature' ? 65 : 20,
  minHeight: props.type === 'signature' ? 65 : 20,
  isDragging: false
})

const clicked = () => {
  emit('update:isMoving', true)
  state.isDragging = true

  if (props.isActive) return

  store.dispatch('pushHistoryStorage')
  emit('update:isActive', true)
  emit('onActiveSingleField')
}

const onDragging = () => {
  emit('onDragging')
}

const onDragEnd = ({
  left,
  top
}) => {
  emit('update:x', left)
  emit('update:y', top)
  emit('update:isMoving', false)
  state.isDragging = false
  if (state.x !== left || state.y !== top) {
    store.dispatch('pushHistoryStorage')
  }
}
const onActive = () => {
  emit('update:isActive', true)
  emit('onActive')
}

const onDeactivated = () => {
  emit('update:isActive', false)
  emit('onDeactivated')
}
const onResizeStop = ({
  width,
  height
}) => {
  emit('update:w', width)
  emit('update:h', height)
  if (state.w !== width || state.h !== height) {
    store.dispatch('pushHistoryStorage')
  }
}

const drager = ref(null)

watch(() => props.x, () => {
  state.x = props.x
})
watch(() => props.y, () => {
  state.y = props.y
})

</script>

<style lang="less" scoped>
.box-shadow {
  border: none;
}

.box-shadow.active {
  &:before {
    outline: none;
  }
}
</style>
