<template>
  <Drag
    ref="drager"
    :aspectRatio="false"
    :class="dragClass"
    :fieldKey="props.fieldKey"
    :h="dragState.h"
    :isActive="props.isActive"
    :isDraggable="!statusState.isFocus"
    :isResizable="!statusState.isFocus"
    :keyboardEvent="props.keyboardEvent"
    :minh="minHeight"
    :minw="25"
    :parentH="dragState.parentH"
    :parentLimitation="true"
    :parentW="dragState.parentW"
    :stickSize="12"
    :sticks="['br']"
    :style="{ zIndex: props.isActive ? 9999 : 98 }"
    :w="dragState.w"
    :x="dragState.x"
    :y="dragState.y"
    class="drager"
    contentClass="box-content"
    @activated="onActive"
    @clicked="onClick"
    @dblclick="onDoubleClick"
    @deactivated="onDeactivated"
    @dragging="onDragging"
    @dragstop="onDragStop"
    @resizestop="onResizeStop"
    @resizing="onResizing"
  >
    <div class="move-form" style="width: 100%; height: 100%; box-sizing: border-box; font-size: 0; line-height: 0; overflow: hidden;">
      <div :style="transform" class="text-wrapper e-txt form-item" style="transform-origin:left top; padding: 0;" @mousedown="handleInputOperation" @mouseup="handleInputOperation">
        <textarea
          ref="textInput"
          v-model="textState.textValue"
          :readonly="isReadonly"
          :style="textStyle"
          class="inputtext"
          placeholder="텍스트"
          style="font-size: 60px; width: 100%; height: 100%; resize: none; line-height: 1; word-break: break-all;letter-spacing: none; padding: 0; overflow: hidden; outline: none; border: none; background: rgba(255, 255, 255, 0.8)"
          @blur="onBlur"
          @focus="onFocus"
          @input="onInput"
          @keydown.ctrl.stop
          @keydown.meta.stop
        >
        </textarea>
      </div>
    </div>
  </Drag>
  <div :style="shadowDivStyle" class="shadow-div">
    <div :style="transform" class="text-wrapper" style="transform-origin:left top; padding: 0;">
      <textarea ref="obTextInput"
                :style="textStyle"
                :value="textState.textValue"
                style=" font-size: 60px; width: 100%; height: 100%; resize: none; line-height: 1; word-break: break-all;letter-spacing: none; padding: 0; overflow: hidden; outline: none; border: none;"></textarea>
    </div>
  </div>

</template>

<script setup>
import { computed, defineEmits, defineProps, reactive, ref, watch } from 'vue'
import Drag from '@/components/common/ucsDrag/Drag.vue'
import { useStore } from 'vuex'

const emit = defineEmits(['update:x', 'update:y', 'update:w', 'update:h', 'update:value', 'update:isActive', 'onActive', 'onActiveSingleField', 'onDeactivated', 'startKeyboardEvent', 'stopKeyboardEvent'])
const props = defineProps(['fieldKey', 'isActive', 'field', 'value', 'isDragging', 'fontSize', 'fontType', 'x', 'y', 'w', 'h', 'pdfW', 'pdfH', 'pdfScale', 'required', 'textAlign', 'keyboardEvent'])
const store = useStore()

const dragState = reactive({
  x: props.x,
  y: props.y,
  w: props.w + 2,
  h: props.h + 2,
  parentW: Math.floor(props.pdfW * props.pdfScale),
  parentH: Math.floor(props.pdfH * props.pdfScale)
})

const textState = reactive({
  textValue: props.value || '',
  fontSize: props.fontSize || 12,
  fontType: props.fontType || 'Noto Sans KR',
  textAlign: props.textAlign || 'left',
  required: props.required || false
})

const statusState = reactive({
  isFocus: false,
  isActive: props.isActive
})

const drager = ref(null)
const textInput = ref(null)
const obTextInput = ref(null)
const oldText = ref('')
let preText = ''

const shadowDivStyle = reactive({
  width: `${dragState.w}px`,
  height: `${dragState.h}px`
})

const size = computed(() => {
  return (props.fontSize * props.pdfScale) / 60
})

const transform = computed(() => {
  const multiple = (1 / size.value) * 100
  return {
    transform: `scale(${size.value})`,
    width: `calc(${multiple}%)`,
    height: `calc(${multiple}%)`
  }
})

const onInput = () => {
  const maxLines = Math.round(textInput.value.clientHeight / 60)
  const inputLine = Math.round(obTextInput.value.scrollHeight / 60)
  if (inputLine > maxLines) {
    textState.textValue = oldText.value
  } else {
    oldText.value = textState.textValue
  }
  emit('update:value', textState.textValue)
}

const setSize = () => {
  if (textInput.value.clientHeight < obTextInput.value.scrollHeight) {
    const height = obTextInput.value.scrollHeight * size.value
    drager.value.setHeight(obTextInput.value.scrollHeight * size.value)
    emit('update:h', height)
  }
}

const onBlur = () => {
  setSize()
  statusState.isFocus = false
  emit('startKeyboardEvent')
  if (preText !== textState.textValue) {
    store.dispatch('pushHistoryStorage')
  }
}

const onResizing = ({
  width,
  height
}) => {
  shadowDivStyle.width = width + 'px'
  shadowDivStyle.height = height + 'px'
}

const onResizeStop = ({
  width,
  height
}) => {
  if (!isNaN(width) && !isNaN(height)) {
    const innerHeight = obTextInput.value.scrollHeight * size.value
    const setHeight = innerHeight > height ? innerHeight : height
    drager.value.setSize({
      width,
      height: setHeight
    })

    shadowDivStyle.width = width + 'px'
    shadowDivStyle.height = setHeight + 'px'
    emit('update:w', width)
    emit('update:h', height)
    if (dragState.w !== width || dragState.h !== height) {
      store.dispatch('pushHistoryStorage')
    }
  }
}

const handleInputOperation = event => {
  if (statusState.isFocus) event.stopPropagation()
}

const onDragging = () => {
  statusState.isFocus = false
}

const onDragStop = ({
  left,
  top
}) => {
  if (!isNaN(left) && !isNaN(top)) {
    emit('update:x', left)
    emit('update:y', top)
    if (dragState.x !== left || dragState.y !== top) {
      store.dispatch('pushHistoryStorage')
    }
  }
}

const onFocus = () => {
  setSize()
  statusState.isFocus = true
  preText = textState.textValue
  emit('stopKeyboardEvent')
}

const onActive = () => {
  emit('update:isActive', true)
  emit('onActive')
}

const onClick = () => {
  if (props.isActive) return
  store.dispatch('pushHistoryStorage')
  emit('update:isActive', true)
  emit('onActiveSingleField')
}

const onDoubleClick = (event) => {
  if (event.target.localName === 'textarea' && !isReadonly.value) {
    textInput.value.focus()
    statusState.isFocus = true
  } else {
    textInput.value.blur()
    statusState.isFocus = false
  }
}

const onDeactivated = () => {
  if (textInput.value) textInput.value.blur()
  emit('update:isActive', false)
  emit('onDeactivated')
}

const stepHeight = computed(() => {
  return Math.ceil(props.pdfScale * textState.fontSize) + 5
})

const minHeight = computed(() => {
  return stepHeight.value
})

const dragClass = computed(() => {
  const order = props.field.signingOrder || 1
  const name = props.field.formType === 'requesterFields' ? 'sign-basic' : `sign${order}`
  return {
    required: textState.required,
    [name]: true
  }
})

const isReadonly = computed(() => {
  /*
  if (props.field.formType) {
    return !['requesterFields', 'docEdit'].includes(props.field.formType)
  } else {
    return false
  }
  */
  return false
})

const textStyle = computed(() => {
  const cursor = statusState.isFocus ? 'text' : 'move'
  return {
    cursor,
    fontFamily: textState.fontType,
    textAlign: textState.textAlign
  }
})

watch(() => props.fontSize, (fontSize) => {
  const size = (fontSize * props.pdfScale) / 60
  if (obTextInput.value) {
    const height = obTextInput.value.scrollHeight * size
    drager.value.setHeight(obTextInput.value.scrollHeight * size)
    emit('update:h', height)
  }
})

watch(() => props.isActive, () => {
  if (textInput.value) textInput.value.blur()
})

watch(() => props.fontSize, () => {
  textState.fontSize = props.fontSize
})

watch(() => props.fontType, () => {
  textState.fontType = props.fontType
})

watch(() => props.required, () => {
  textState.required = props.required
})

watch(() => props.textAlign, () => {
  textState.textAlign = props.textAlign
})

watch(() => props.x, () => {
  dragState.x = props.x
})
watch(() => props.y, () => {
  dragState.y = props.y
})

</script>

<style lang="less" scoped>
.shadow-div {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -99;
  // z-index: 99;
  visibility: hidden;
}

.box-content {
  border: 1px solid #BBB;
}

.box-content:focus,
.box-content:active {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (hover: hover) {
  .box-content:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

.drager.active {
  box-shadow: 0 0 5px #CCC;
}

.drager.sign1 {
  border: 1px solid rgba(39, 208, 176, 0.2);

  textarea {
    background: rgba(39, 208, 176, 0.25) !important;
  }
}

.drager.sign2 {
  border: 1px solid rgba(255, 202, 16, 0.5);

  textarea {
    background: rgba(255, 202, 16, 0.25) !important;
  }
}

.drager.sign3 {
  border: 1px solid rgba(109, 172, 247, 0.5);

  textarea {
    background: rgba(109, 172, 247, 0.25) !important;
  }
}

.drager.sign4 {
  border: 1px solid rgba(205, 117, 206, 0.5);

  textarea {
    background: rgba(205, 117, 206, 0.25) !important;
  }
}

.drager.sign5 {
  border: 1px solid rgba(165, 212, 110, 0.5);

  textarea {
    background: rgba(165, 212, 110, 0.25) !important;
  }
}

.inputtext::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
</style>
