<template>
  <a-modal v-model:visible="visible" :footer="false">
    <div v-if="data !== null">
      <div v-for="field in data" :key="field.key" class="page">
        {{ field }}
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  props: ['selectedFields'],
  setup (props) {
    const state = reactive({
      visible: false,
      data: props.selectedFields
    })

    const show = () => {
      state.data = props.selectedFields
      state.visible = true
    }

    const hide = () => {
      state.visible = false
      state.data = null
    }

    const activate = () => {
      if (state.visible) {
        hide()
      } else {
        show()
      }
    }

    return {
      ...toRefs(state),
      show,
      hide,
      activate
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding: 10px;
  border: 1px solid #BBB;
}
</style>
