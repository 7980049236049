<template>
  <a-modal v-model:visible="visible" :footer="false">
    <div v-if="data !== null">
      <div style="display: flex; justify-content: space-between">
        <div class="undo" v-if="data.undo.length > 0" style="overflow-y: scroll; height: 600px; width: 45%">
          <div style="text-align: center">
            UNDO
          </div>
          <div class="page" v-for="(history, i) in data.undo" :key="i">
            historyIndex: {{ i }}
            <div v-for="(pdfData, pdfIndex) in history.pdfData" :key="pdfIndex">
              <div v-for="(fields, fieldsIndex) in pdfData.fields" :key="fieldsIndex">
                <dl>
                  <dt>{{ fields.type }}, {{ fields.fieldName }}</dt>
                  <dd>x: {{ fields.x }} | y: {{ fields.y }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="redo" v-if="data.redo.length > 0" style="overflow-y: scroll; height: 600px; width: 45%">
          <div style="text-align: center">
            REDO
          </div>
          <div class="page" v-for="(history, i) in data.redo" :key="i">
            historyIndex: {{ i }}
            <div v-for="(pdfData, pdfIndex) in history.pdfData" :key="pdfIndex">
              <div v-for="(fields, fieldsIndex) in pdfData.fields" :key="fieldsIndex">
                <dl>
                  <dt>{{ fields.type }}, {{ fields.fieldName }}</dt>
                  <dd>x: {{ fields.x }} | y: {{ fields.y }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const state = reactive({
      visible: false,
      data: null
    })

    const show = () => {
      state.visible = true
      state.data = {
        undo: store.state.documents.undoHistoryStorage,
        redo: store.state.documents.redoHistoryStorage
      }
    }

    const hide = () => {
      state.visible = false
      state.data = null
    }

    const activate = () => {
      if (state.visible) {
        hide()
      } else {
        show()
      }
    }

    return {
      ...toRefs(state),
      show,
      hide,
      activate
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding: 10px;
  border: 1px solid #BBB;
}
</style>
