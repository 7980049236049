<template>
  <div class="sign-draw">
    <div class="write-box box-wrap bg-light-gray" :style="{ height: `${height}px` }">
      <p class="tip text-center text-gray" v-if="!isWrited">이 곳에 사인을 그려주세요.</p>
      <canvas id="writePad" @click="touchWritePad"></canvas>
    </div>
    <div class="btn-txt-group text-right buttons">
      <button type="button" class="btn-txt text-gray text-line" @click="resetPad">모두 지우기</button>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, toRefs } from 'vue'
import SignaturePad from 'signature_pad'

export default {
  setup () {
    const state = reactive({
      signaturePad: null,
      isWrited: false,
      height: 0
    })
    const resizeCanvas = () => {
      var ratio = Math.max(window.devicePixelRatio || 1, 1)
      const canvas = document.getElementById('writePad')
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d').scale(ratio, ratio)
      state.signaturePad.clear() // otherwise isEmpty() might return incorrect value
    }
    onMounted(() => {
      const boxWidth = document.querySelector('.write-box').clientWidth
      state.height = boxWidth / 2.588
      nextTick(() => {
        state.signaturePad = new SignaturePad(document.getElementById('writePad'))
        state.signaturePad.onBegin = () => {
          touchWritePad()
        }
        resizeCanvas()
      })
    })
    const resetPad = () => {
      state.signaturePad.clear()
      state.isWrited = false
    }
    const getSignSvg = () => {
      if (state.isWrited) {
        const base64Img = state.signaturePad.toDataURL('image/svg+xml')
        return `<svg width="260" height="100" version="1.1" viewBox="0 0 260 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <image width="260px" height="100px" xlink:href="${base64Img}" /></svg>`
      } else {
        return null
      }
    }

    const touchWritePad = () => {
      state.isWrited = true
      state.errorInfo = ''
    }
    return {
      resetPad,
      getSignSvg,
      touchWritePad,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.sign-draw {
  margin: 0 auto;
  padding: 0 20px;
  // width: 580px;
}

.write-box {
  position: relative;
  border: 1px solid #eee;
  // width: 580px;
  // height: 220px;
  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
  }
}

.buttons {
  margin-top: 5px
}
</style>
