<template>
  <a-spin :spinning="isLoading" size="large">
    <div class="sign-uesed-wrap bg-light-gray">
      <SvgPreview :type="type" :svgs="svgs" @selectSvg="onSelectSvg" :hasMore="hasMore" @onClickLoadMore="loadMore"/>
    </div>
  </a-spin>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { getSignaturesUsed } from 'api/sign'
import SvgPreview from 'components/signing/signaure/SvgPrevirew'

export default {
  props: ['type'],
  components: { SvgPreview },
  setup (props, { emit }) {
    const svgPreview = ref(null)
    const state = reactive({
      svgs: [],
      pageData: {
        limit: 10,
        page: 1
      },
      hasMore: false,
      isLoading: false,
      curSelected: null
    })

    const loadMore = () => {
      fetchData()
    }
    const fetchData = () => {
      state.isLoading = true
      getSignaturesUsed({
        ...state.pageData,
        type: props.type
      }).then(res => {
        state.svgs.push(...res.result.list)
        if (res.result.totalPage > state.pageData.page) {
          state.hasMore = true
          state.pageData.page++
        } else {
          state.hasMore = false
        }
      }).finally(() => {
        state.isLoading = false
      })
    }

    const getSelectSvg = () => {
      return state.curSelected
    }

    const onSelectSvg = (svg) => {
      emit('selectSvg', svg)
    }

    onMounted(() => {
      fetchData()
    })

    return {
      loadMore,
      svgPreview,
      getSelectSvg,
      onSelectSvg,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.sign-uesed-wrap {
  margin: 0 auto;
  padding: 14px;
  max-width: 580px;
  height: 40vh;
  overflow-y: scroll;
  border: 1px solid #eee;
}
</style>
