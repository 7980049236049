<template>
  <div class="e-cont">
    <div class="e-inner-sm">
      <!-- <div class="box">
        <div class="box-body">
          <label for="agree_personal" class="input-chk round">
            <input
              type="checkbox"
              name="chk_agree"
              id="agree_personal"
              value="1"
            />
            <span class="checkbox"></span>
            <span class="txt"><strong>서명 참여자의 서명 순서를 지정합니다.</strong></span>
          </label>
        </div>
      </div> -->
      <draggable filter=".ant-input" v-model="signers" ghostClass="ghost" animation="300" :scroll="true" itemKey="dragKey">
        <template #item="{element, index}">
          <Signer :key="element.dragKey" :index="index" :signer="element" @updateSigner="onUpdateSigner" @removeSigner="onRemoveSigner" :operationType="operationType"/>
        </template>
        <!-- //다섯번째 서명 (input 알럿 노출 화면)-->
      </draggable>
      <!-- //서명 리스트 그룹 -->
      <div class="box">
        <div class="btn-center">
          <button type="button" class="btn btn-lg btn-block btn-white" @click="addSigner" v-if="signers.length<5">
            <span class="ico-plus">
              <svg
                data-icon="plus"
                width="16px"
                height="16px"
                fill="#828282"
                aria-hidden="true"
                viewBox="64 64 896 896"
                focusable="false"
              >
                <path
                  d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                ></path>
                <path
                  d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                ></path>
              </svg>
            </span>
            서명 참여자 추가
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import Signer from 'components/signing/Signer'
import draggable from 'vuedraggable'
import { useStore } from 'vuex'
import { genKey } from 'utils/helper'
import { Modal } from 'ant-design-vue'

const createParticipant = (order, type = 'sign') => {
  const signer = {
    name: '',
    signingMethodType: 'email',
    signingContactInfo: '',
    signingOrder: order,
    message: '',
    attachments: [],
    dragKey: genKey()
  }
  if (type === 'template') signer.roleName = ''
  return signer
}

export default {
  components: {
    Signer,
    draggable
  },
  props: {
    operationType: {
      type: String,
      default: 'sign'
    },
    isEditting: {
      type: Boolean,
      defalut: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const userInfo = store.state.user.userInfo
    const initSigners = () => {
      const signersInit = [
        {
          name: userInfo.name,
          signingMethodType: 'email',
          signingContactInfo: userInfo.email,
          signingOrder: 1,
          message: '',
          attachments: [],
          dragKey: genKey()
        },
        {
          name: '',
          signingMethodType: '',
          signingContactInfo: '',
          signingOrder: 2,
          message: '',
          attachments: [],
          dragKey: genKey()
        }
      ]

      const templateSignersInit = [
        {
          name: '',
          roleName: '',
          signingMethodType: '',
          signingContactInfo: '',
          signingOrder: 1,
          message: '',
          attachments: [],
          dragKey: genKey()
        }
      ]
      const signers = props.operationType === 'template' ? templateSignersInit : signersInit
      store.dispatch('updateSigners', signers)
    }

    if (!props.isEditting) initSigners()

    const state = reactive({})

    const onRemoveSigner = dragKey => {
      const signers = store.state.documents.signers
      if (signers.length > 1) {
        Modal.confirm({
          title: '경고',
          content: '해당 참여자에 설정된 필드 및 컴포넌트 정보가 초기화됩니다. 삭제하시겠습니까?',
          okText: '확인',
          cancelText: '취소',
          onOk () {
            emit('removeSigner', dragKey)
            setTimeout(() => {
              const index = signers.findIndex(item => item.dragKey === dragKey)
              signers.splice(index, 1)
              refreshOrder()
            }, 200)
          }
        })
      }
    }

    const addSigner = () => {
      if (store.state.documents.signers.length < 5) {
        store.state.documents.signers.push(createParticipant(store.state.documents.signers.length + 1, props.operationType))
      }
    }
    const onUpdateSigner = (signer) => {
      const signers = store.state.documents.signers
      signers[signers.findIndex(item => item.signingOrder === signer.signingOrder)] = signer
      store.dispatch('updateSigners', signers)
    }
    onMounted(() => {
      // setTimeout(() => {
      //   [].forEach.call(document.querySelectorAll('.ant-input'), function (item) {
      //     item.addEventListener('pointerdown', (e) => {
      //       e.stopPropagation()
      //     })
      //     item.addEventListener('mousedown', (e) => {
      //       e.stopPropagation()
      //     })
      //   })
      // }, 200)
    })

    const refreshOrder = () => {
      const orderMap = {}
      store.state.documents.signers = store.state.documents.signers.map((item, index) => {
        item.signingOrder = index + 1
        orderMap[item.dragKey] = item.signingOrder
        return item
      })
      return orderMap
    }

    return {
      onRemoveSigner,
      addSigner,
      onUpdateSigner,
      refreshOrder,
      ...toRefs(store.state.documents),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.4;
  // background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
