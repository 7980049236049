export default class ImageHandler {
  constructor (canvasDom, img, width, height, color) {
    this.canvasDom = canvasDom
    this.img = img
    // this.width = width
    // this.height = height
    this.width = img.width
    this.height = img.height
    this.canvas = canvasDom
    this.context = this.canvas.getContext('2d')
    this.baseImageInfo = null
    // this.canvas.width = width
    // this.canvas.height = height
    this.canvas.width = img.width
    this.canvas.height = img.height
    this.imgRect = this._containImg(0, 0, width, height, img.width, img.height)
    this.mainColor = ''
    this.imageDataUrl = ''
    this.color = color
    this._init()
  }

  // transImage (percent) {
  //   const { dx, dy } = this.imgRect
  //   const delta = Math.floor(percent <= 30 ? 255 / 30 : 255 / 70)
  //   const step = percent <= 30 ? (30 - percent) * delta : percent * delta
  //   const imageInfo = this._copyImageData(this.baseImageInfo)
  //   const pixiArr = imageInfo.data
  //   for (let i = 0; i < pixiArr.length; i += 4) {
  //     const current = [pixiArr[i], pixiArr[i + 1], pixiArr[i + 2], pixiArr[i + 3]]
  //     if (this._matchColor(current, this.mainColor, percent / 100)) {
  //       pixiArr[i] = pixiArr[i] + step
  //       pixiArr[i + 1] = percent <= 30 ? pixiArr[i + 1] - step : pixiArr[i + 1]
  //       pixiArr[i + 2] = percent <= 30 ? pixiArr[i + 2] - step : pixiArr[i + 2]
  //       pixiArr[i + 3] = percent <= 30 ? pixiArr[i + 3] + step : (pixiArr[i + 3] - Math.floor(step * 0.6))
  //     }
  //   }
  //   // const { dWidth, dHeight } = this.imgRect
  //   this.context.clearRect(0, 0, this.width, this.height)
  //   this.context.putImageData(imageInfo, dx, dy)
  // }

  transImage (percent) {
    // const { dx, dy } = this.imgRect
    const delta = Math.floor(percent <= 30 ? 255 / 30 : 255 / 70)
    const step = percent <= 30 ? (30 - percent) * delta : percent * delta
    const imageInfo = this._copyImageData(this.baseImageInfo)
    const pixiArr = imageInfo.data
    if (this.color === 'red') {
      for (let i = 0; i < pixiArr.length; i += 4) {
        const current = [pixiArr[i], pixiArr[i + 1], pixiArr[i + 2], pixiArr[i + 3]]
        if (this._matchColor(current, [255, 0, 0], percent)) {
          pixiArr[i] = 255
          pixiArr[i + 1] = pixiArr[i + 1] - step
          pixiArr[i + 2] = pixiArr[i + 2] - step
          pixiArr[i + 3] = percent <= 30 ? pixiArr[i + 3] + step : (pixiArr[i + 3] - Math.floor(step * 0.6))
        } else {
          pixiArr[i] = 255
          pixiArr[i + 1] = pixiArr[i + 1] - step
          pixiArr[i + 2] = pixiArr[i + 2] - step
          pixiArr[i + 3] = 0
        }
      }
    } else {
      for (let i = 0; i < pixiArr.length; i += 4) {
        const current = [pixiArr[i], pixiArr[i + 1], pixiArr[i + 2], pixiArr[i + 3]]
        if (this._matchColor(current, [0, 0, 0], percent)) {
          pixiArr[i] = 0
          pixiArr[i + 1] = pixiArr[i + 1] - step
          pixiArr[i + 2] = pixiArr[i + 2] - step
          pixiArr[i + 3] = percent <= 30 ? pixiArr[i + 3] + step : (pixiArr[i + 3] - Math.floor(step * 0.6))
        } else {
          pixiArr[i] = 0
          pixiArr[i + 1] = pixiArr[i + 1] - step
          pixiArr[i + 2] = pixiArr[i + 2] - step
          pixiArr[i + 3] = 0
        }
      }
    }

    // const { dWidth, dHeight } = this.imgRect
    this.context.clearRect(0, 0, this.width, this.height)
    // this.context.putImageData(imageInfo, dx, dy)
    this.context.putImageData(imageInfo, 0, 0)
    this.imageDataUrl = this.canvas.toDataURL('image/png')
  }

  getImageDataUrl () {
    return this.imageDataUrl
  }

  _init () {
    this._drawImage()
    // const { dx, dy, dWidth, dHeight } = this.imgRect
    // const imageInfo = this.context.getImageData(dx, dy, dWidth, dHeight)
    const imageInfo = this.context.getImageData(0, 0, this.width, this.height)
    const mainColor = this._getCounts(imageInfo.data)[0].color.split(',').map(item => Number(item))
    this.mainColor = mainColor
    const range = 20
    const pixiArr = imageInfo.data
    for (let i = 0; i < pixiArr.length; i += 4) {
      const r = pixiArr[i]
      const g = pixiArr[i + 1]
      const b = pixiArr[i + 2]
      const a = pixiArr[i + 3]
      if (Math.abs(r - mainColor[0]) <= range &&
        Math.abs(g - mainColor[1]) <= range &&
        Math.abs(b - mainColor[2]) <= range &&
        Math.abs(a - mainColor[3]) <= range) {
        imageInfo.data[i + 3] = 0
      }
      imageInfo.data[i] = this.color === 'red' ? 255 : 0
    }
    this.baseImageInfo = imageInfo
    // this.context.putImageData(imageInfo, dx, dy)
    this.context.putImageData(imageInfo, 0, 0)
    this.imageDataUrl = this.canvas.toDataURL('image/png')
  }

  _copyImageData (imageInfo) {
    return new ImageData(new Uint8ClampedArray(imageInfo.data), imageInfo.width, imageInfo.height)
  }

  _drawImage () {
    // const { dx, dy, dWidth, dHeight } = this.imgRect
    // this.context.clearRect(0, 0, dWidth, dHeight)
    // this.context.drawImage(this.img, dx, dy, dWidth, dHeight)

    this.context.clearRect(0, 0, this.width, this.height)
    this.context.drawImage(this.img, 0, 0, this.width, this.height)
  }

  _containImg = (sx, sy, boxW, boxH, sourceW, sourceH) => {
    var dx = sx
    var dy = sy
    var dWidth = boxW
    var dHeight = boxH
    if (sourceW > sourceH || (sourceW === sourceH && boxW < boxH)) {
      dHeight = sourceH * dWidth / sourceW
      dy = sy + (boxH - dHeight) / 2
    } else if (sourceW < sourceH || (sourceW === sourceH && boxW > boxH)) {
      dWidth = sourceW * dHeight / sourceH
      dx = sx + (boxW - dWidth) / 2
    }
    return {
      dx,
      dy,
      dWidth,
      dHeight
    }
  }

  _matchColor (current, target, range) {
    // for (let i = 0; i < 3; i++) {
    //   if (!((1 - range) * target[i] <= current[i] && (1 + range) * target[i] >= current[i])) return false
    // }
    // return true
    const rResult = target[0] - current[0]
    const gResult = target[1] - current[1]
    const bResult = target[2] - current[2]
    return Math.sqrt(rResult * rResult + gResult * gResult + bResult * bResult) <= range * 10
  }

  // _isTransparent

  _getCounts (data, ignore = []) {
    const countMap = {}

    for (let i = 0; i < data.length; i += 4 /* 4 gives us r, g, b, and a */) {
      const alpha = data[i + 3]
      // skip FULLY transparent pixels
      // if (alpha === 0) continue

      const rgbComponents = Array.from(data.subarray(i, i + 3))

      // skip undefined data
      if (rgbComponents.indexOf(undefined) !== -1) continue

      // const color = alpha && alpha !== 255
      //   ? `rgba(${[...rgbComponents, alpha].join(',')})`
      //   : `rgb(${rgbComponents.join(',')})`
      const color = alpha && alpha !== 255
        ? `${[...rgbComponents, alpha].join(',')}`
        : `${rgbComponents.join(',')}`

      // skip colors in the ignore list
      if (ignore.indexOf(color) !== -1) continue

      if (countMap[color]) {
        countMap[color].count++
      } else {
        countMap[color] = {
          color,
          count: 1
        }
      }
    }

    const counts = Object.values(countMap)
    return counts.sort((a, b) => b.count - a.count)
  }
}
