<template>
  <a-menu v-if="visible" id="optionBox" :style="setPosition" class="context-menu">
    <a-menu-item v-if="type === 'field'">
      <a id="contextMenuItem" @click="cutFields">
        잘라내기
        <span v-if="isMacOS">(Command + x)</span>
        <span v-else>(Ctrl + x)</span>
      </a>
    </a-menu-item>
    <a-menu-item v-if="type === 'field'">
      <a id="contextMenuItem" @click="copyFields">
        복사
        <span v-if="isMacOS">(Command + c)</span>
        <span v-else>(Ctrl + c)</span>
      </a>
    </a-menu-item>
    <a-menu-item v-if="type === 'page'" :disabled="disablePaste">
      <a id="contextMenuItem" @click="pasteFields($event, null)">
        붙여넣기
        <span v-if="isMacOS">(Command + v)</span>
        <span v-else>(Ctrl + v)</span>
      </a>
    </a-menu-item>
    <a-menu-item v-if="type === 'page'" :disabled="disablePaste">
      <a id="contextMenuItem" @click="pasteFields($event, position)">
        현 위치에 붙여넣기
        <span v-if="isMacOS">(Command + Shift + v)</span>
        <span v-else>(Ctrl + Shift + v)</span>
      </a>
    </a-menu-item>
  </a-menu>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    isMacOS: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      visible: false,
      type: 'page',
      position: {
        // 컨텍스트 메뉴 출력용 좌표
        pageX: 0,
        pageY: 0,

        // 마우스 위치 붙여넣기용 좌표
        offsetX: 0,
        offsetY: 0,
        page: 1
      },
      disablePaste: false
    })

    const showContextMenu = (
      type = 'page',
      position = {
        pageX: 0,
        pageY: 0,
        offsetX: 0,
        offsetY: 0,
        page: 1
      }) => {
      state.visible = false
      state.type = type
      state.position = position
      state.disablePaste = store.getters.getClipBoard.length === 0 || !store.getters.getClipBoard
      state.visible = true
    }

    const hideContextMenu = () => {
      state.visible = false
    }

    const setPosition = computed(() => {
      return {
        left: `${state.position.pageX}px`,
        top: `${state.position.pageY}px`
      }
    })

    const cutFields = (e) => {
      e.stopPropagation()
      state.visible = false
      emit('cutFields')
    }

    const copyFields = (e) => {
      e.stopPropagation()
      state.visible = false
      emit('copyFields')
    }

    const pasteFields = (e, position) => {
      e.stopPropagation()
      state.visible = false
      emit('pasteFields', position)
    }

    return {
      ...toRefs(state),
      showContextMenu,
      hideContextMenu,
      setPosition,
      cutFields,
      copyFields,
      pasteFields
    }
  }
}
</script>

<style lang="less" scoped>

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.context-menu {
  border: 1px solid #8d8d8d;
  position: fixed;
  z-index: 900;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  span {
    color: #8d8d8d;
    font-size: 11px;
  }
}
</style>
