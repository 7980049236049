<template>
  <div class="side-bar right-bar">
    <a-button class="top-btn" @click="changeDocFile">문서 변경하기</a-button>
    <div id="optionBox" ref="scroll" class="box-body">
      <div v-show="selectedFields.length < 1" class="content">
        <div class="pdf-previewer">
          <div v-for="index in pdfNums" :key="index" :class="{'current-page': currentPage === index}" class="pdf-card" @click="onClickPage(index)">
            <div class="layer"></div>
            <span class="page-index">{{ index }}</span>
            <canvas :id="`canvas-${index}`"></canvas>
          </div>
        </div>
      </div>
      <div v-if="selectedFields.length > 0" class="content">
        <div class="header sort-btn">
          <a-popover>
            <template #content>
              왼쪽 정렬
            </template>
            <AlignLeftOutlined :style="!sortable ? {color: '#bbbbbb', cursor: 'default'} : {}" @click="sortFields('left')"/>
          </a-popover>
          <a-popover>
            <template #content>
              오른쪽 정렬
            </template>
            <AlignRightOutlined :style="!sortable ? {color: '#bbbbbb', cursor: 'default'} : {}" @click="sortFields('right')"/>
          </a-popover>
          <a-popover>
            <template #content>
              위 정렬
            </template>
            <AlignLeftOutlined :style="!sortable ? {color: '#bbbbbb', cursor: 'default'} : {}" style="transform: rotate(90deg);" @click="sortFields('top')"/>
          </a-popover>
          <a-popover>
            <template #content>
              아래 정렬
            </template>
            <AlignRightOutlined :style="!sortable ? {color: '#bbbbbb', cursor: 'default'} : {}" style="transform: rotate(90deg);" @click="sortFields('bottom')"/>
          </a-popover>
        </div>
        <div v-show="title.length > 0" class="header">
          <div class="title">
            {{ title }}
          </div>
        </div>
        <div v-if="showOptions.required" class="form-group">
          <div class="side">
            <div class="form-label">필수여부</div>
            <div>
              <a-switch v-model:checked="formState.required" class="switch" @change="changeValue('required')"/>
            </div>
          </div>
        </div>
        <div v-if="showOptions.dateFormat" class="form-group">
          <div class="form-label">날짜 형식</div>
          <div class="form-value">
            <a-select
              ref="dateFormatSelector"
              v-model:value="formState.dateFormat"
              style="width: 100%"
              @change="changeValue('dateFormat'); datePick();"
              @select="this.$refs.dateFormatSelector.blur()"
            >
              <a-select-option v-for="option in dateFormatOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div v-if="showOptions.dateValue" class="form-group">
          <div class="form-label">날짜 선택</div>
          <div class="form-value">
            <a-date-picker
              ref="datePicker"
              v-model:value="formState.dateValue"
              :format="formState.dateFormat"
              inputReadOnly
              @change="datePick"
              @select="this.$refs.datePicker.blur()"
            />
          </div>
        </div>
        <!--
        <div v-if="showOptions.textStyleFontType" class="form-group">
          <div class="form-label">글꼴</div>
          <div class="form-value">
            <a-select
              ref="textStyleFontTypeSelector"
              v-model:value="formState.textStyleFontType"
              style="width: 100%"
              @change="changeValue('textStyleFontType')"
              @select="this.$refs.textStyleFontTypeSelector.blur()"
            >
              <a-select-option v-for="option in fontTypeOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </div>
        </div>
        -->
        <div v-if="showOptions.textStyleFontSize" class="form-group">
          <div class="form-label">글자 크기</div>
          <div class="form-value">
            <a-select
              ref="textStyleFontSizeSelector"
              v-model:value="formState.textStyleFontSize"
              style="width: 100%"
              @change="changeValue('textStyleFontSize')"
              @select="this.$refs.textStyleFontSizeSelector.blur()"
            >
              <a-select-option v-for="option in fontSizeOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div v-if="showOptions.addComma" class="form-group">
          <div class="side">
            <div class="form-label">자릿수 표기</div>
            <div>
              <a-switch v-model:checked="formState.addComma" class="switch" @change="changeValue('addComma')"/>
            </div>
          </div>
        </div>
        <div v-if="showOptions.textAlign" class="form-group">
          <div class="form-label">정렬</div>
          <div class="form-value">
            <a-radio-group v-model:value="formState.textAlign" @change="changeValue('textAlign')">
              <a-radio-button value="left">
                <AlignLeftOutlined/>
              </a-radio-button>
              <a-radio-button value="center">
                <AlignCenterOutlined/>
              </a-radio-button>
              <a-radio-button value="right">
                <AlignRightOutlined/>
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div v-if="showOptions.groupRange" class="form-group">
          <div class="form-label">필드 선택 개수 설정</div>
          <div class="form-value multi-form">
            <a-select
              ref="groupRangeOptionSelector"
              v-model:value="formState.groupRangeOption"
              size="small"
              style="width: 100%;"
              @change="changeGroupRangeOption"
              @select="this.$refs.groupRangeOptionSelector.blur()"
            >
              <a-select-option v-for="option in groupRangeOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
            </a-select>
          </div>
          <div class="form-value">
            <div v-show="formState.groupRangeOption === 'over' || formState.groupRangeOption === 'range'" class="multi-form">
              <a-select
                ref="groupRangeOverSelector"
                v-model:value="formState.groupRange[0]"
                class="range-select"
                size="small"
                @change="changeGroupRange"
                @select="this.$refs.groupRangeOverSelector.blur()"
              >
                <a-select-option v-for="option in groupRangeMinOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
              </a-select>
              개 이상
            </div>
            <div v-show="formState.groupRangeOption === 'less' || formState.groupRangeOption === 'range'" class="multi-form">
              <a-select
                ref="groupRangeLessSelector"
                v-model:value="formState.groupRange[1]"
                class="range-select"
                size="small"
                @change="changeGroupRange"
                @select="this.$refs.groupRangeLessSelector.blur()"
              >
                <a-select-option v-for="option in groupRangeMaxOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
              </a-select>
              개 이하
            </div>
            <div v-show="formState.groupRangeOption === 'exact'" class="multi-form">
              <a-select
                ref="groupRangeExactSelector"
                v-model:value="formState.groupRange[0]"
                class="range-select"
                size="small"
                @change="changeGroupRange"
                @select="this.$refs.groupRangeExactSelector.blur()"
              >
                <a-select-option v-for="option in groupRangeMinOptions" id="optionBox" :key="option.value" :value="option.value">{{ option.name }}</a-select-option>
              </a-select>
              개 선택
            </div>
          </div>
        </div>
        <div v-if="showOptions.signing" class="form-group">
          <div class="form-label">서명 입력하기</div>
          <div class="form-value sign-btn">
            <a-button @click="clickSignBtn('sign')">사인</a-button>
            <a-button @click="clickSignBtn('stamp')">도장</a-button>
          </div>
        </div>
        <div v-if="showOptions.removeSign" class="form-group">
          <div class="form-label">서명 지우기</div>
          <div class="form-value">
            <a-button class="sign-remove-btn" @click="removeRequesterSignData">서명 지우기</a-button>
          </div>
        </div>
        <div v-if="showOptions.fieldName" class="form-group">
          <div class="form-label">
            필드이름(선택)
            <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="left">
              <button class="btn-info" type="button">?</button>
              <template #title>
                <p class="content">
                  대량전송 또는 모바일 서명요청 이용 시 해당 필드를 구분할 수 있는 필드 이름을 입력합니다.
                </p>x
              </template>
            </a-tooltip>
          </div>
          <div class="form-value" @keydown.ctrl.stop @keydown.meta.stop @keydown.delete.exact.stop>
            <a-input v-model:value="formState.fieldName" style="width: 100%" @change="changeValue('fieldName')"/>
          </div>
        </div>
      </div>
      <div v-if="selectedFields.length > 0" class="footer">
        <a-button v-if="showOptions.grouping" @click="grouping">그룹 설정</a-button>
        <a-button v-if="showOptions.ungrouping" @click="ungrouping">그룹 해제</a-button>
        <a-button @click="removeField">삭제</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons-vue'
import { FormatTime } from '@/utils/util'

export default {
  props: ['selectedFields', 'selectedGroup', 'pdfNums'],
  components: {
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined
  },
  setup (props, { emit }) {
    const state = reactive({
      selectedFields: props.selectedFields,
      selectedGroup: null,
      fontSizeOptions: [
        {
          value: 6,
          name: '6pt'
        },
        {
          value: 7,
          name: '7pt'
        },
        {
          value: 8,
          name: '8pt'
        },
        {
          value: 9,
          name: '9pt'
        },
        {
          value: 10,
          name: '10pt'
        },
        {
          value: 11,
          name: '11pt'
        },
        {
          value: 12,
          name: '12pt'
        },
        {
          value: 13,
          name: '13pt'
        },
        {
          value: 14,
          name: '14pt'
        },
        {
          value: 15,
          name: '15pt'
        },
        {
          value: 16,
          name: '16pt'
        },
        {
          value: 17,
          name: '17pt'
        },
        {
          value: 18,
          name: '18pt'
        },
        {
          value: 24,
          name: '24pt'
        },
        {
          value: 30,
          name: '30pt'
        },
        {
          value: 36,
          name: '36pt'
        },
        {
          value: 42,
          name: '42pt'
        },
        {
          value: 48,
          name: '48pt'
        },
        {
          value: 54,
          name: '54pt'
        },
        {
          value: 60,
          name: '60pt'
        }
      ],
      fontTypeOptions: [
        {
          value: 'Noto Sans KR',
          name: 'Noto Sans KR'
        }
      ],
      dateFormatOptions: [
        {
          value: 'YYYY-MM-DD',
          name: 'YYYY-MM-DD'
        },
        {
          value: 'YYYY/MM/DD',
          name: 'YYYY/MM/DD'
        },
        {
          value: 'YYYY년 MM월 DD일',
          name: 'YYYY년 MM월 DD일'
        }
      ],
      groupRangeOptions: [
        {
          value: 'over',
          name: '이상'
        },
        {
          value: 'less',
          name: '이하'
        },
        {
          value: 'exact',
          name: '일치'
        },
        {
          value: 'range',
          name: '범위'
        }
      ],
      groupRangeMinOptions: [],
      groupRangeMaxOptions: [],
      currentPage: 1,
      formState: {
        type: '',
        required: '',
        dateFormat: '',
        dateValue: '',
        textStyleFontType: '',
        textStyleFontSize: '',
        addComma: '',
        textAlign: '',
        fieldName: '',
        groupRangeOption: 'range',
        groupRange: [0, 1],
        groupLength: 1
      },
      showOptions: {
        required: false,
        dateFormat: false,
        dateValue: false,
        textStyleFontType: false,
        textStyleFontSize: false,
        addComma: false,
        textAlign: false,
        groupRange: false,
        signing: false,
        removeSign: false,
        fieldName: false,
        grouping: false,
        ungrouping: false
      }
    })

    const checkSelectedFields = () => {
      state.selectedFields = props.selectedFields

      if (state.selectedFields.length > 0) {
        state.formState.type = state.selectedFields[0].type || ''
        state.formState.required = state.selectedFields[0].required || ''
        state.formState.dateFormat = state.selectedFields[0].dateFormat || ''
        state.formState.dateValue = state.selectedFields[0].dateValue || ''
        state.formState.textStyleFontType = state.selectedFields[0].textStyleFontType || ''
        state.formState.textStyleFontSize = state.selectedFields[0].textStyleFontSize || ''
        state.formState.addComma = state.selectedFields[0].addComma || ''
        state.formState.textAlign = state.selectedFields[0].textAlign || ''
        state.formState.fieldName = state.selectedFields[0].fieldName || ''

        for (let i = 1; i < state.selectedFields.length; i++) {
          if (!state.selectedFields[i].type || state.selectedFields[i].type !== state.formState.type) state.formState.type = ''
          if (!state.selectedFields[i].required || state.selectedFields[i].required !== state.formState.required) state.formState.required = ''
          if (!state.selectedFields[i].dateFormat || state.selectedFields[i].dateFormat !== state.formState.dateFormat) state.formState.dateFormat = ''
          if (!state.selectedFields[i].dateValue || state.selectedFields[i].dateValue !== state.formState.dateValue) state.formState.dateValue = ''
          if (!state.selectedFields[i].textStyleFontType || state.selectedFields[i].textStyleFontType !== state.formState.textStyleFontType) state.formState.textStyleFontType = ''
          if (!state.selectedFields[i].textStyleFontSize || state.selectedFields[i].textStyleFontSize !== state.formState.textStyleFontSize) state.formState.textStyleFontSize = ''
          if (!state.selectedFields[i].addComma || state.selectedFields[i].addComma !== state.formState.addComma) state.formState.addComma = ''
          if (!state.selectedFields[i].textAlign || state.selectedFields[i].textAlign !== state.formState.textAlign) state.formState.textAlign = ''
          if (!state.selectedFields[i].fieldName || state.selectedFields[i].fieldName !== state.formState.fieldName) state.formState.fieldName = ''
        }
      }
    }

    /**
     * editPdf에서 특정 필드들 선택시 사이드바의 내용 변경
     */
    watch(() => props.selectedFields.length, () => {
      checkSelectedFields()
      setOptionVisible()
    })

    watch(() => props.selectedGroup, () => {
      if (!props.selectedGroup) {
        state.selectedGroup = null
        return
      }
      state.selectedGroup = props.selectedGroup

      state.formState.groupRange = [state.selectedGroup.min, state.selectedGroup.max]
      state.formState.groupLength = state.selectedGroup.count
      state.formState.groupRangeOption = state.selectedGroup.groupRangeOption

      resetGroupRangeOption()
      setOptionVisible()
    })

    const title = computed(() => {
      if (state.selectedFields.length < 1) return ''
      const titles = {
        signature: '사인/도장',
        text: '텍스트',
        checkbox: '체크박스',
        date: '날짜',
        number: '숫자'
      }
      const title = titles[state.selectedFields[0].type]
      for (let i = 1; i < state.selectedFields.length; i++) {
        if (titles[state.selectedFields[i].type] !== title) return ''
      }
      // return titles[state.currentField.type]
      return title
    })

    const setOptionVisible = () => {
      if (!state.selectedFields || state.selectedFields.length === 0) {
        state.showOptions = {
          required: false,
          dateFormat: false,
          dateValue: false,
          textStyleFontType: false,
          textStyleFontSize: false,
          addComma: false,
          textAlign: false,
          groupRange: false,
          signing: false,
          removeSign: false,
          fieldName: false,
          grouping: false,
          ungrouping: false
        }
      } else {
        const result = {
          required: true,
          dateFormat: true,
          dateValue: true,
          textStyleFontType: true,
          textStyleFontSize: true,
          addComma: true,
          textAlign: true,
          groupRange: true,
          signing: true,
          removeSign: true,
          fieldName: true,
          grouping: true,
          ungrouping: true
        }

        for (const index in state.selectedFields) {
          if (state.selectedFields[index].formType === 'requesterFields' || state.selectedFields[index].groupParentName) result.required = false
          if (state.selectedFields[index].type !== 'date') {
            result.dateFormat = false
            result.dateValue = false
          }
          if (state.selectedFields[index].type !== 'text' &&
            state.selectedFields[index].type !== 'date' &&
            state.selectedFields[index].type !== 'number') {
            result.textStyleFontType = false
            result.textStyleFontSize = false
            result.textAlign = false
          }
          if (state.selectedFields[index].type !== 'number') result.addComma = false
          if (!state.selectedFields || state.selectedFields.length === 0) result.fieldName = false
          if (state.selectedFields[index].formType !== 'requesterFields' || state.selectedFields[index].type !== 'signature' || state.selectedFields[index].signData) result.signing = false
          if (state.selectedFields[index].formType !== 'requesterFields' || state.selectedFields[index].type !== 'signature' || !state.selectedFields[index].signData) result.removeSign = false
        }

        if (state.selectedFields.length < 2) result.grouping = false

        const signingOrder = state.selectedFields[0].signingOrder
        if (!signingOrder) result.grouping = false

        let groupName = null
        let ungroupingFieldsCount = 0
        for (const index in state.selectedFields) {
          if (state.selectedFields[index].type !== 'checkbox' || state.selectedFields[index].signingOrder !== signingOrder) {
            result.grouping = false
          } else if (!state.selectedFields[index].groupParentName) {
            ungroupingFieldsCount++
          } else if (state.selectedFields[index].groupParentName && groupName === null) {
            groupName = state.selectedFields[index].groupParentName
          } else if (state.selectedFields[index].groupParentName && state.selectedFields[index].groupParentName !== groupName) result.grouping = false
        }
        if (ungroupingFieldsCount === 0) result.grouping = false

        if (!state.selectedGroup) {
          result.groupRange = false
          result.ungrouping = false
        }

        state.showOptions = Object.assign(state.showOptions, result)
      }
    }

    const changeValue = (option) => {
      emit('changeField', state.formState, option)
    }

    const removeField = () => {
      emit('removeField')
    }

    const scroll = ref()
    const onClickPage = (index) => {
      state.currentPage = index
      emit('clickPreviewer', index)
    }
    const setCurrentPage = (pageIndex) => {
      state.currentPage = pageIndex
      const card = document.querySelector('.current-page')
      scroll.value.scrollTo(0, card.offsetTop - card.clientHeight)
    }

    const datePick = () => {
      if (!state.formState.dateValue) {
        state.formState.textValueText = ''
      } else {
        state.formState.textValueText = FormatTime(state.formState.dateValue, state.formState.dateFormat)
      }
      emit('changeField', state.formState, 'dateValue')
    }

    const sortable = computed(() => {
      return state.selectedFields && state.selectedFields.length > 1
    })

    const grouping = () => {
      emit('groupingFields')
    }

    const ungrouping = () => {
      emit('ungroupingFields')
    }

    /**
     * 선택한 필드들 정렬
     * @param position 정렬 방향
     */
    const sortFields = (position) => {
      if (!state.selectedFields || state.selectedFields.length < 2) return
      emit('sortFields', position)
    }

    const changeGroupRange = () => {
      if (state.formState.groupRangeOption === 'exact') state.formState.groupRange[1] = state.formState.groupRange[0]
      resetGroupRangeOption()
      changeValue('group')
    }

    const changeGroupRangeOption = () => {
      if (state.formState.groupRangeOption === 'over') {
        state.formState.groupRange[0] = 1
        state.formState.groupRange[1] = state.formState.groupLength
      } else if (state.formState.groupRangeOption === 'less') {
        state.formState.groupRange[0] = 0
        state.formState.groupRange[1] = 1
      } else if (state.formState.groupRangeOption === 'exact') {
        state.formState.groupRange[0] = 1
        state.formState.groupRange[1] = 1
      } else if (state.formState.groupRangeOption === 'range') {
        state.formState.groupRange[0] = 0
        state.formState.groupRange[1] = state.formState.groupLength
      }
      resetGroupRangeOption()
      changeValue('group')
    }

    const resetGroupRangeOption = () => {
      if (state.formState.groupRangeOption === 'over') {
        state.groupRangeMinOptions = []
        for (let i = 0; i <= state.formState.groupLength; i++) {
          state.groupRangeMinOptions.push({
            value: i,
            name: i
          })
        }
      } else if (state.formState.groupRangeOption === 'less') {
        state.groupRangeMaxOptions = []
        for (let i = 0; i <= state.formState.groupLength; i++) {
          state.groupRangeMaxOptions.push({
            value: i,
            name: i
          })
        }
      }
      if (state.formState.groupRangeOption === 'exact') {
        state.groupRangeMinOptions = []
        state.groupRangeMaxOptions = []
        for (let i = 0; i <= state.formState.groupLength; i++) {
          state.groupRangeMinOptions.push({
            value: i,
            name: i
          })
          state.groupRangeMaxOptions.push({
            value: i,
            name: i
          })
        }
      }
      if (state.formState.groupRangeOption === 'range') {
        // 범위일 경우 이하값은 "이상값"을 넘지 못하게, 이상값은 "이하값"보다 낮지 않게
        state.groupRangeMinOptions = []
        state.groupRangeMaxOptions = []
        // 이상값의 최대값 설정
        for (let i = 0; i <= state.formState.groupRange[1]; i++) {
          state.groupRangeMinOptions.push({
            value: i,
            name: i
          })
        }
        // 이하값의 최소값 설정
        for (let i = state.formState.groupRange[0]; i <= state.formState.groupLength; i++) {
          state.groupRangeMaxOptions.push({
            value: i,
            name: i
          })
        }
      }
    }

    const clickSignBtn = (type) => {
      emit('clickSignBtn', type)
    }

    const removeRequesterSignData = () => {
      emit('removeRequesterSignData', null)
      nextTick(() => {
        setOptionVisible()
      })
    }

    const changeDocFile = () => {
      emit('changeDocFile')
    }

    return {
      ...toRefs(state),
      title,
      checkSelectedFields,
      setOptionVisible,
      changeValue,
      removeField,
      onClickPage,
      scroll,
      setCurrentPage,
      datePick,
      sortable,
      grouping,
      ungrouping,
      sortFields,
      changeGroupRangeOption,
      changeGroupRange,
      clickSignBtn,
      removeRequesterSignData,
      changeDocFile
    }
  }
}
</script>

<style lang="less" scoped>
.ant-divider-horizontal {
  margin: 12px 0;
}

.form-group {
  margin-bottom: 10px;

  .side {
    display: flex;
    justify-content: space-between;
  }

  .form-label {
    color: #555555;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .form-value {
    width: 100%;
  }

  .multi-form {
    margin-bottom: 5px;
  }
}

.box-body {
  overflow: auto;
}

.header {
  text-align: left;
  background-color: #fafafa;
  border-bottom: #d9d9d9 1px solid;
  margin: -25px -25px 25px -25px;
  padding: 12px 16px;

  .title {
  }
}

.footer {
  button {
    width: 100%;
    margin-bottom: 12px;
  }
}

.btn-info {
  transform: scale(0.8);
}

.pdf-previewer {
  background: #fff;
  height: 100%;

  .pdf-card {
    position: relative;
    margin: 3px auto;
    width: 100px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      transition: background 0.4s;
    }

    .page-index {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 16px;
      font-weight: 500;
    }

    .field-tip {
      position: absolute;
      top: 35%;
      width: 50%;
      text-align: center;
      margin-left: 25%;
    }
  }

  .current-page {
    .layer {
      background: none;
      border: 2px solid #1890ff;
    }
  }
}

.sort-btn {
  display: flex;
  justify-content: space-evenly;
}

.sign-btn {
  display: flex;
  justify-content: space-between;

  .ant-btn {
    width: 64px;
  }
}

.sign-remove-btn {
  width: 100%;
}

.range-select {
  width: 65%;
}

.switch {
  transform: scale(0.9);
}

.ant-radio-button-wrapper {
  width: 33%;
}

.top-btn {
  margin-bottom: 10px;
  width: 100%;
  padding: 12px 16px;
  height: auto;
  font-size: 14px;
}
</style>
