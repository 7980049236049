<template>
  <div :class="selectedTheme" class="box">
    <div class="signer-select">
      <a-radio-group v-if="signers.length > 1" v-model:value="selectedSigner">
        <a-radio-button v-for="element in signers" :key="element.signingOrder" :value="element.signingOrder">{{ checkNameOutput(element.name || element.roleName) }}</a-radio-button>
      </a-radio-group>
      <div class="b-tit sub-tit">
        <span class="label s-left">{{ signer.signingOrder }}</span>
        <div class="tit-txt">
          <h3>{{ signTip }}<span class="roleName">{{ signer.roleName }}</span></h3>
          <p v-if="signer.name">{{ signer.name }}<br/>({{ signer.signingContactInfo }})</p>
        </div>
      </div>
      <a-divider/>
    </div>
    <div class="box-body">
      <div class="form-group">
        <div ref="selectForm" :class="{active: isExpand}" class="select-area form-control">
          <span class="select-btn select-click" @click="isExpand = !isExpand">
            <svg
              aria-hidden="true"
              class=""
              data-icon="down"
              fill="currentColor"
              focusable="false"
              height="12px"
              viewBox="64 64 896 896"
              width="12px"
            >
              <path
                d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
              ></path>
            </svg>
          </span>
          <div class="select-chk" @click.prevent="onClickSignType">
            <span class="select-ico"><i :class="currentSelect.icon" class="ico ico-s"></i></span>
            <span class="select-txt">{{ currentSelect.text }}<em class="num"></em></span>
          </div>
          <ul class="select-list">
            <li v-for="(option, index) in selectOptions" :key="index" :class="{selected: option.value === currentSelect.value}" class="option" @click="onSelect(option)">
              <span class="select-ico">
                <i :class="option.icon" class="ico ico-s "></i>
              </span>
              <span class="select-txt">{{ option.text }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- //사인&도장 선택버튼 -->
      <div class="form-group">
        <div class="input-group form-control form-ico" role="button" @click="onClickText">
          <div class="input-group-addon">
            <i class="ico ico-s ico-text"></i>
          </div>
          <span class="sign-btn">텍스트 <em class="num"></em></span>
        </div>
      </div>
      <!-- //텍스트 -->
      <div class="form-group">
        <div class="input-group form-control form-ico" role="button" @click="onClickCheckbox">
          <div class="input-group-addon">
            <i class="ico ico-s ico-check"></i>
          </div>
          <span class="sign-btn">체크박스 <em class="num"></em></span>
        </div>
      </div>
      <!-- //체크박스 -->
      <div class="form-group">
        <div class="input-group form-control form-ico" role="button" @click="onClickDate">
          <div class="input-group-addon">
            <i class="ico2 ico-s ico-date"></i>
          </div>
          <span class="sign-btn">날짜 <em class="num"></em></span>
        </div>
      </div>
      <!-- //날짜 -->
      <div class="form-group">
        <div class="input-group form-control form-ico" role="button" @click="onClickNumber">
          <div class="input-group-addon">
            <i class="ico2 ico-s ico-number"></i>
          </div>
          <span class="sign-btn">숫자 <em class="num"></em></span>
        </div>
      </div>
      <!-- //숫자 -->
    </div>
    <div class="box-btn">
      <button v-if="!signer.attachments || signer.attachments.length === 0" class="btn btn-gray btn-block file-btn modal-trigger" data-modal-id="file-request" type="button" @click="addAttachment">첨부파일
        요청
      </button>
      <!-- 첨부파일 요청 버튼 클릭해서 보이는 모달에 내용을 입력하면 노출되는 화면 -->
      <div v-else class="file-wrap" role="button">
        <div class="file">
          <span @click="addAttachment">첨부파일 요청 <em>({{ signer.attachments.length }})</em></span>
          <a-tooltip :color="'#FFF'" overlayClassName="signer-tooltip" placement="right">
            <button class="btn-info" type="button">?</button>
            <template #title>
              <p class="title">첨부파일 요청</p>
              <p class="content">첨부 받을 파일 정보입니다.<br/>
                눌러서 더 자세히 확인 및 설정할 수 있습니다.</p>
            </template>
          </a-tooltip>
          <ul>
            <li v-for="(item, index) in signer.attachments" :key="index">{{ item.attachmentType }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <AttachmentModal ref="attachmentModal" :userName="signer.name" @confirmAttachments="confirmAttachments"/>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue'
import AttachmentModal from 'components/signing/AttachmentModal'
import { useStore } from 'vuex'
import { onClickOutside } from '@vueuse/core'

export default {
  components: { AttachmentModal },
  props: ['signers'],
  emits: ['clickInfo', 'selectField'],
  setup (props, { emit }) {
    const store = useStore()
    const attachmentModal = ref(null)
    const state = reactive({
      selectedSigner: 1,
      count: 0,
      isExpand: false,
      selectOptions: [
        {
          icon: 'ico-user',
          text: '사인/도장',
          value: 'USER'
        },
        {
          icon: 'ico-sign',
          text: '사인',
          value: 'SIGN'
        },
        {
          icon: 'ico-stamp',
          text: '도장',
          value: 'STAMP'
        }
      ],
      currentSelect: {
        icon: 'ico-user',
        text: '사인/도장',
        value: 'USER'
      }
    })

    const signer = computed(() => {
      return props.signers[state.selectedSigner - 1]
    })

    const selectedTheme = computed(() => {
      return `sign-child${state.selectedSigner}`
    })

    const createSignInfo = (type) => {
      const info = {
        signingOrder: signer.value.signingOrder,
        dragKey: signer.value.dragKey,
        formType: 'fields',
        type
      }
      if (type === 'signature') {
        return { ...info, ...state.currentSelect }
      } else {
        return info
      }
    }

    const onClickSignType = () => {
      emit('selectField', createSignInfo('signature'))
    }
    const onSelect = (value) => {
      state.currentSelect = value
      state.isExpand = false
      emit('selectField', createSignInfo('signature'))
    }
    const onClickText = () => {
      emit('clickInfo', createSignInfo('text'))
    }
    const onClickCheckbox = () => {
      emit('clickInfo', createSignInfo('checkbox'))
    }
    const onClickDate = () => {
      emit('clickInfo', createSignInfo('date'))
    }
    const onClickNumber = () => {
      emit('clickInfo', createSignInfo('number'))
    }
    // const signers = store.state.documents.signers
    const signTip = computed(() => {
      const singerTip = ['첫 번째 서명 참여자', '두 번째 서명 참여자', '세 번째 서명 참여자', '네 번째 서명 참여자', '다섯 번째 서명 참여자']
      return singerTip[signer.value.signingOrder - 1]
    })
    const addAttachment = () => {
      const attachments = signer.value.attachments.length > 0 ? signer.value.attachments : null
      attachmentModal.value.show(attachments)
    }
    const confirmAttachments = (attachments) => {
      store.dispatch('updateSigner', {
        ...signer.value,
        attachments
      })
    }

    const selectForm = ref(null)
    const clickOut = () => {
      state.isExpand = false
    }

    onClickOutside(selectForm, clickOut)

    const checkNameOutput = (name) => {
      if (props.signers.length === 5) {
        if (!name || name.length < 2) {
          return name
        } else {
          return name.substr(0, 1)
        }
      } else if (props.signers.length === 4) {
        if (!name || name.length < 3) {
          return name
        } else {
          return name.substr(0, 2)
        }
      } else if (props.signers.length === 3) {
        if (!name || name.length < 4) {
          return name
        } else {
          return name.substr(0, 3)
        }
      } else if (props.signers.length === 2) {
        if (!name || name.length < 7) {
          return name
        } else {
          return name.substr(0, 6)
        }
      } else {
        return name
      }
    }

    return {
      signer,
      selectedTheme,
      onClickSignType,
      onSelect,
      onClickText,
      onClickCheckbox,
      onClickDate,
      onClickNumber,
      signTip,
      addAttachment,
      attachmentModal,
      confirmAttachments,
      checkNameOutput,
      ...toRefs(props),
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.roleName {
  margin-left: 5px;
  font-size: 12px;
  color: #4a98ff;
}

</style>
