<template>
  <div ref="dragArea" class="drag-area"/>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    dragFieldId: {
      type: String
    },
    pdfData: {
      type: Array
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const dragArea = ref()
    const state = reactive({
      dragPosition: {
        x: 0,
        y: 0
      },
      isDragging: false,
      pdfData: props.pdfData
    })

    watch(() => props.pdfData, () => {
      state.pdfData = props.pdfData
    })

    const globalMouseDownEvent = (ev) => {
      if (ev.button === 0) {
        state.isDragging = true
        ev.preventDefault()
        const pointerX = ev.pageX
        const pointerY = ev.pageY
        state.dragPosition = {
          x: pointerX,
          y: pointerY
        }
        dragArea.value.style.display = 'block'
      } else if (ev.button === 2) {
        ev.preventDefault()
      }
    }

    /**
     * 드래그 영역 표시
     */
    const globalMouseMoveEvent = (ev) => {
      if (ev.button === 0 && state.isDragging) {
        ev.preventDefault()
        const pageX = ev.pageX
        const pageY = ev.pageY
        const left = (pageX < state.dragPosition.x ? pageX : state.dragPosition.x)
        const top = (pageY < state.dragPosition.y ? pageY : state.dragPosition.y)
        const width = Math.abs(state.dragPosition.x - pageX)
        const height = Math.abs(state.dragPosition.y - pageY)
        // dragArea.style.transform = `translate(${left}px, ${top}px) scale(${width}, ${height})`
        dragArea.value.style.left = `${left}px`
        dragArea.value.style.top = `${top}px`
        dragArea.value.style.width = `${width}px`
        dragArea.value.style.height = `${height}px`
      }
    }

    /**
     * 드래그 영역 모두 선택
     */
    const globalMouseUpEvent = (ev) => {
      if (ev.button === 0 && state.isDragging) {
        ev.preventDefault()
        state.dragPosition = {
          x: 0,
          y: 0
        }
        const dragRect = dragArea.value.getBoundingClientRect()
        let fieldNum = 0
        for (let pageIndex = 0; pageIndex < state.pdfData.length; pageIndex++) {
          for (let fieldIndex = 0; fieldIndex < state.pdfData[pageIndex].fields.length; fieldIndex++) {
            const field = state.pdfData[pageIndex].fields[fieldIndex]
            if (field.type === 'group') continue
            const fieldRect = document.getElementById(field.key).getBoundingClientRect()

            if (
              dragRect.right > fieldRect.right &&
              dragRect.left < fieldRect.left &&
              dragRect.bottom > fieldRect.bottom &&
              dragRect.top < fieldRect.top
            ) {
              field.active = true
              emit('onActive', field)
              fieldNum++
            }
          }
          for (let fieldIndex = 0; fieldIndex < state.pdfData[pageIndex].requesterFields.length; fieldIndex++) {
            const field = state.pdfData[pageIndex].requesterFields[fieldIndex]
            const fieldRect = document.getElementById(field.key).getBoundingClientRect()

            if (
              dragRect.right > fieldRect.right &&
              dragRect.left < fieldRect.left &&
              dragRect.bottom > fieldRect.bottom &&
              dragRect.top < fieldRect.top
            ) {
              field.active = true
              emit('onActive', field)
              fieldNum++
            }
          }
        }
        if (fieldNum > 0) store.dispatch('pushHistoryStorage')
        dragArea.value.style.left = '0px'
        dragArea.value.style.top = '0px'
        dragArea.value.style.width = '0px'
        dragArea.value.style.height = '0px'
        dragArea.value.style.display = 'none'
        state.isDragging = false
        emit('onActiveGroup')
      }
    }

    /**
     * 마우스 이벤트 DOM에 등록
     */
    onMounted(() => {
      document.addEventListener('mousemove', globalMouseMoveEvent)
      document.addEventListener('mouseup', globalMouseUpEvent)
      document.getElementById(props.dragFieldId).addEventListener('mousedown', globalMouseDownEvent)
    })

    return {
      dragArea
    }
  }
}
</script>

<style lang="less" scoped>
.drag-area {
  position: fixed;
  transform-origin: left top;
  background-color: #0f78d94d;
  display: none;
  z-index: 900;
}
</style>
